import axios from "axios"
import Gen from "@/libs/Gen.js"

const baseUrl = process.env.VUE_APP_BASE_URL,
  baseUrlAPI = `${process.env.VUE_APP_API_URL}/api/fo`

const httpCurl = axios.create({
  baseURL: '',
})

const http = axios.create({
  baseURL: baseUrlAPI,
})

function setToken(token) {
  token ? (token = `Bearer ${token}`) : (token = "")

  Gen.apiHeaderAuth(token)
}

function setBaseToken() {
  Gen.apiHeader()
}

export { baseUrl, baseUrlAPI, http, httpCurl, setToken, setBaseToken }
