import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store/index"
import NotFound from "../views/NotFound.vue"

import * as guards from "./guard"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: true,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/run-events",
    name: "RunEvents",
    component: () => import("../views/RunEvents.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/run-event/detail/:pageSlug?",
    name: "EventDetail",
    component: () => import("../views/EventDetail.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: true,
      isFooter: true,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/run-event/detail/check-availability/:pageSlug",
    name: "CheckAvailability",
    component: () => import("../views/CheckAvailability.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/my-purchase",
    name: "TransactionsHistory",
    component: () => import("../views/TransactionsHistory.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.authenticated,
  },
  {
    path: "/run-event/detail/check-out/:pageSlug",
    name: "DetailPackage",
    component: () => import("../views/DetailPackage.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/run-event/transaction/:pageSlug",
    name: "DetailTransaction",
    component: () => import("../views/DetailTransaction.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/transaction/:pageSlug",
    name: "DetailParentTransaction",
    component: () => import("../views/DetailParentTransaction.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/transaction/receipt/:pageSlug",
    name: "Invoice",
    component: () => import("../views/Invoice.vue"),
    meta: {
      isHeader: false,
      isHalfBanner: false,
      isFooter: false,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/transaction/itinerary/:pageSlug",
    name: "Itinerary",
    component: () => import("../views/Itinerary.vue"),
    meta: {
      isHeader: false,
      isHalfBanner: false,
      isFooter: false,
      mobileNavigation: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("../views/Gallery.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
  },
  {
    path: "/news-articles/:pageSlug?",
    name: "NewsArticles",
    component: () => import("../views/NewsArticles.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/news-articles/category/:pageSlug?",
    name: "NewsArticlesCategory",
    component: () => import("../views/NewsArticles.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
      scrollToTop: false,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/news-articles/tag/:pageSlug?",
    name: "NewsArticlesTag",
    component: () => import("../views/NewsArticles.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/news-articles/detail/:pageSlug?",
    name: "NewsArticlesDetail",
    component: () => import("../views/NewsArticlesDetail.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },

  // Auth
  {
    path: "/auth/sign-in",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      isHeader: true,
      redirectIfLoggedIn: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
    beforeEnter: guards.unauthenticated,
  },
  {
    path: "/auth/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      isHeader: true,
      redirectIfLoggedIn: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      isHeader: true,
      redirectIfLoggedIn: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
  },
  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
  },
  {
    path: "/transaction/confirm",
    name: "ConfirmTransaction",
    component: () => import("../views/ConfirmTransaction.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: false,
      mobileNavigation: false,
    },
  },
  {
    path: "/confirm-email/:confirmationKey",
    name: "ConfirmEmail",
    component: () => import("../views/ConfirmEmail.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: false,
      mobileNavigation: false,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: () => import("../views/TermsConditions.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.baseAuthenticated,
  },

  // Private Page
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/private/Dashboard.vue"),
    meta: {
      isHeader: true,
      redirectIfNotLogin: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.authenticated,
  },
  {
    path: "/account-information/:pageSlug?",
    name: "AccountInformation",
    component: () => import("../views/private/AccountInformation.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.authenticated,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../views/private/ChangePassword.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.authenticated,
  },
  {
    path: "/transaction/:pageSlug",
    name: "DetailTransactionPrivate",
    component: () => import("../views/private/DetailTransactionPrivate.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
    beforeEnter: guards.authenticated,
  },
  {
    path: "/purchase/completing-data/:token",
    name: "FollowupQuestion",
    component: () => import("../views/FollowupQuestion.vue"),
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
  },
  {
    path: "*",
    redirect: {
      name: "404",
    },
    meta: {
      isHeader: true,
    },
  },
]

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    // scroll to anchor by returning the selector
    if (to.params.savePosition) {
      return false
    }

    if (to.meta) {
      // specify offset of the element
      if (Object.prototype.hasOwnProperty.call(to.meta, "scrollToTop")) {
        return to.meta.scrollToTop
      }

      return { y: 0 }
    }
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["authenticated"]

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: "Dashboard" })
  }

  if (to.meta.redirectIfNotLogin && !isLoggedIn) {
    return next({ name: "Login" })
  }

  return next()
})

export default router
