import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import VueMeta from "vue-meta"
import VueCookies from "vue-cookies"
import VueSocketIO from 'vue-socket.io'
import socketio from 'socket.io-client'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import DatePicker from "vue2-datepicker"
import VCalendar from "v-calendar"
import vSelect from "vue-select"

import CookiesConfig from "./config/cookies"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "remixicon/fonts/remixicon.css"
import "vue2-datepicker/index.css"
import "vue-select/dist/vue-select.css"

/* 3rd party plugins */
import "@/libs/alias"
import "@/libs/vee-validate"
import "@/libs/vue-select"

Vue.config.productionTip = false

Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
})

Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(DatePicker)
Vue.use(VCalendar)
Vue.use(vSelect)

Vue.component("v-select", vSelect)

Vue.$cookies.config(
  CookiesConfig.expiredTime,
  CookiesConfig.path,
  CookiesConfig.domain,
  CookiesConfig.secure
)

store.dispatch("watchAuth").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app")
})
