import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "@/libs/Gen"

export default {
  namespaced: true,
  state: {
    hero: {},
    filters: {},
    calendar: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_API_DATA(state, data) {
      state.hero = data.hero.data
      state.calendar = data.calendar
      state.filters = data.filters
    },
  },
  actions: {
    async getData({ commit, rootState }, params = {}) {
      try {
        rootState.loading.screen = true

        const data = await http.get("/calendar", {
          headers: Gen.apiHeader(),
          params: {
            ...params,
          },
        })

        commit("SET_API_DATA", data.data)

        rootState.staticseo = data.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
