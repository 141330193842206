import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"

import Gen from "@/libs/Gen"

export default {
  namespaced: true,
  state: {
    address: {},
    socialmedia: {},
    contact: {},
    announcement: {},
    content: {},
    partners: null,
    year: ''
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_FOOTER_DATA(state, data) {
      state.address = data.address
      state.socialmedia = data.socialmedia
      state.contact = data.contact
      state.content = data.content
      state.partners = data.partners
      state.year = data.year
      state.announcement = data.announcement
    },
  },
  actions: {
    async getFooterContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/footer", {
          headers: Gen.apiHeader(),
        })

        commit("SET_FOOTER_DATA", resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
