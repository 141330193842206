import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"

import Gen from "@/libs/Gen"
import router from "../../router"

export default {
  namespaced: true,
  state: {
    feature: {},
    mostviewed: {},
    newsarticles: {},
    newsarticletags: {},
    newsarticlecategory: {},
    newsarticletype: {},
    categories: [],
    detail: {},
    related: [],
    text: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_NEWS_ARTICLES_CONTENT(state, data) {
      state.feature = data.feature
      state.mostviewed = data.mostviewed
      state.newsarticles = data.newsarticles
      state.newsarticletags = data.newsarticletags
      state.newsarticlecategory = data.newsarticlecategory
      state.newsarticletype = data.newsarticletype
      state.categories = data.categories
      state.text = data.text
    },
    SET_NEWS_ARTICLES_BY_CATEGORY(state, data) {
      state.newsarticles = data.newsarticles
    },
    SET_NEWS_ARTICLE_DETAIL_CONTENT(state, data) {
      state.detail = data.detail
      state.related = data.related
    },
  },
  actions: {
    async getNewsArticlesContent({ commit, rootState }, params) {
      try {
        const { path, slug, page, callback } = params

        if (!slug) {
          rootState.loading.screen = true
        }

        const resp = await http.get(path, {
          headers: Gen.apiHeader(),

          params: {
            page: page,
          },
        })

        if (callback) {
          callback()
        }

        commit("SET_NEWS_ARTICLES_CONTENT", resp.data)
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        if (error.response.status === 404) {
          router.push({ name: "404" })
        } else if (error.response.status === 400) {
          router.push({ name: "NewsArticles" })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async getNewsArticleDetailContent(
      { commit, rootState },
      { path, callback }
    ) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/news-article/" + path, {
          headers: Gen.apiHeader(),
        })

        commit("SET_NEWS_ARTICLE_DETAIL_CONTENT", resp.data)
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        if ((error.response || {}).status === 400) {
          router.push({ name: "404" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
