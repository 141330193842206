<template>
  <div>
    <h1 class="d-none">RunDeGlobe</h1>
    <header class="header-black" v-if="isBlack">
      <div class="fixed-header py-2" v-if="isAnnouncement && ((announcement || {}).content || {}).show_section == 'Y'">
        <div class="container">
          <div class="d-flex justify-content-center align-items-center">
            <i class="ri-information-line fs-2rem"></i>
            <span class="bold">{{ ((announcement || {}).content || {}).title }}</span>
            <a :href="((announcement || {}).content || {}).url" :target="((announcement || {}).content || {}).target" class="bold underline text-black ml-1rem">{{ ((announcement || {}).content || {}).button }}</a>
          </div>
          <div class="icon-close">
            <i class="ri-close-line fs-2rem pointer" @click="removeAnnouncement"></i>
          </div>
        </div>
      </div>
      <b-navbar toggleable="lg" class="container py-3">
        <router-link to="/" class=" text-capitalize text-white navbar-brand
            text-white
            font-weight-bold
            d-flex
            align-items-center">
          <picture>
            <source :srcset="`${baseUrl}/assets/image/logo/logoblack.webp`" type="image/webp">
            <img :src="`${baseUrl}/assets/image/logo/logoblack.png`" alt="Logo RunDeGlobe" class="w-75">
          </picture>
        </router-link>
        
        <b-navbar-toggle target="nav-collapse">
          <i class="ri-menu-4-line"></i>
        </b-navbar-toggle>

         <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav m-auto">

            <li class="nav-item">
              <router-link 
                :to="{ name: 'RunEvents' }" 
                class="nav-link text-capitalize text-white" 
                :class="
                  childRunEvent.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >run events</router-link
              >
            </li>

            <li class="nav-item">
              <router-link 
                :to="{ name: 'Gallery' }" class="nav-link text-capitalize text-white"
                :class="$route.name == 'Gallery' && $route.query.page > 0 ? 'router-link-exact-active router-link-active' : ''"
                >gallery</router-link
              >
            </li>

            <li class="nav-item" v-if="Object.keys(this.user).length !== 0">
              <router-link 
                :to="{ name: 'TransactionsHistory' }" 
                class="nav-link text-capitalize text-white"
                :class="childMyPurchase.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >My Purchases</router-link
              >
            </li>

            <li class="nav-item">
              <router-link 
                :to="{ name: 'NewsArticles' }" 
                class="nav-link text-capitalize text-white"
                :class="childNewsArticles.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >Articles</router-link
              >
            </li>

            <li class="nav-item active">
              <router-link :to="{ name: 'About' }" class="nav-link text-capitalize text-white"
                >About Us</router-link
              >
            </li>

            <li class="nav-item">
              <router-link :to="{name: 'Contact'}" class="nav-link text-capitalize text-white"
                >Contact Us</router-link
              >
            </li>

          </ul>

          <div @click="searchNewsArticle" class="search-sec black">
            <i class="ri-search-line icon-search"></i>
          </div>

          <RdgButton @clicked="$router.push({ name: 'AccountInformation'}).catch(() => {})" type="primary" text="Profile" v-if="userToken"/>
          <RdgButton @clicked="$router.push({ name: 'Login', query: { ref: refSignIn }}).catch(() => {})" type="primary" text="Sign In" v-else-if="!userToken && refSignIn"/>
          <RdgButton @clicked="$router.push({ name: 'Login'}).catch(() => {})" type="primary" text="Sign In" v-else/>
         </b-collapse>
      </b-navbar>
    </header>
    <header class="header-white" v-else>
      <div class="fixed-header py-2" v-if="isAnnouncement && ((announcement || {}).content || {}).show_section == 'Y'">
        <div class="container">
          <div class="d-flex justify-content-center align-items-center">
            <i class="ri-information-line fs-2rem"></i>
            <span class="bold">{{ ((announcement || {}).content || {}).title }}</span>
            <a :href="((announcement || {}).content || {}).url" :target="((announcement || {}).content || {}).target" class="bold underline text-black ml-1rem">{{ ((announcement || {}).content || {}).button }}</a>
          </div>
          <div class="icon-close">
            <i class="ri-close-line fs-2rem pointer" @click="removeAnnouncement"></i>
          </div>
        </div>
      </div>
      <b-navbar toggleable="lg" class="container py-3">
        <router-link to="/" class=" text-capitalize text-white navbar-brand
            text-white
            font-weight-bold
            d-flex
            align-items-center">
          <picture>
            <source :srcset="`${baseUrl}/assets/image/logo/logowhite.webp`" type="image/webp">
            <img :src="`${baseUrl}/assets/image/logo/logowhite.png`" alt="Logo RunDeGlobe" class="w-75">
          </picture>
        </router-link>
        <b-navbar-toggle target="nav-collapse">
          <i class="ri-menu-4-line"></i>
        </b-navbar-toggle>

         <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav m-auto">

            <li class="nav-item">
              <router-link 
                :to="{ name: 'RunEvents' }" 
                class="nav-link text-capitalize text-white"
                :class="childRunEvent.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >run events</router-link
              >
            </li>

            <li class="nav-item">
              <router-link 
                :to="{ name: 'Gallery' }" 
                class="nav-link text-capitalize text-white"
                :class="$route.name == 'Gallery' && $route.query.page > 0 ? 'router-link-exact-active router-link-active' : ''"
                >gallery</router-link
              >
            </li>

            <li class="nav-item" v-if="Object.keys(this.user).length !== 0">
              <router-link 
                :to="{ name: 'TransactionsHistory' }" 
                class="nav-link text-capitalize text-white"
                :class="childMyPurchase.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >My Purchases</router-link
              >
            </li>

            <li class="nav-item">
              <router-link 
                :to="{ name: 'NewsArticles' }" 
                class="nav-link text-capitalize text-white"
                :class="childNewsArticles.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >Articles</router-link
              >
            </li>

            <li class="nav-item">
              <router-link :to="{name: 'About'}" class="nav-link text-capitalize text-white"
                >About Us</router-link
              >
            </li>

            <li class="nav-item">
              <router-link :to="{name: 'Contact'}" class="nav-link text-capitalize text-white"
                >Contact Us</router-link
              >
            </li>

          </ul>

          <div @click="searchNewsArticle" class="search-sec white">
            <i class="ri-search-line icon-search"></i>
          </div>

          <RdgButton @clicked="$router.push({ name: 'AccountInformation'}).catch(() => {})" type="primary" text="Profile" v-if="userToken"/>
          <RdgButton @clicked="$router.push({ name: 'Login', query: { ref: refSignIn }}).catch(() => {})" type="primary" text="Sign In" v-else-if="!userToken && refSignIn"/>
          <RdgButton @clicked="$router.push({ name: 'Login'}).catch(() => {})" type="primary" text="Sign In" v-else/>
         </b-collapse>
      </b-navbar>
    </header>
     <div class="popup-search">
      <div class="container mt-5">
        <div class="row d-flex flex-column justify-content-center align-items-center">
            <div class="col-lg-6">
              <div class="card">
                <h3 :class="isMobile() ? 'text-center border-bottom-1rem' : 'border-bottom-1rem'">Search </h3>
                <!-- <span class="light fs-1rem" :class="isMobile() ? 'text-center' : ''">We have {{ totalNewsArticles }} news articles & {{ totalRunEvents }} Run Events in RunDeGlobe</span> -->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form class="form-sec" @submit.prevent="handleSubmit(doSearch)">
                    <div class="input-box mt-4"> 
                    <input type="text" id="inputPopupsearch" v-model="inputSearch" class="form-control" placeholder="e.g BMW Berlin Marathon">
                    <i class="ri-close-fill" @click="clearInputSearch" v-if="inputSearch"></i>
                      </div>
                      <div class="content">

                      <span v-if="inputSearch" class="fs-1rem">Search RunDeGlobe for "{{ inputSearch }}"</span>
                      <hr>
                      <div class="my-4" v-if="resultSearch != null">
                        <p>News Articles</p>
                        <div v-if="(resultSearch|| {}).length != 0">
                          <div class="list border-bottom" v-for="(v, k) in resultSearch" :key="k">
                            <div class="d-flex flex-column ml-3"> 
                              <a @click="pushToNewsArticles(v.slug)" class="heading-search text-main"><span v-html="v.title"></span></a>
                              <span class="fs-1rem light ellipsis">{{ getParagraphFromCK(v.description) }}</span> 
                              <span class="fs-1rem light color-gray">{{ v.type }} - {{ v.author_name }}</span> 
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <span class="fs-1rem">We don't have any news or articles with that title</span>
                        </div>
                      </div>
                      <div class="my-4" v-if="resultRunEvents != null">
                        <p>Run Events</p>
                        <div v-if="(resultRunEvents || {}).length != 0">
                          <div class="list border-bottom" v-for="(v, k) in resultRunEvents" :key="k">
                            <div class="d-flex flex-column ml-3"> 
                              <a @click="pushToRunEvent(v.slug)" class="text-main"><span>{{ v.name }}</span></a>
                              <span class="fs-1rem light ellipsis">{{ getParagraphFromCK(v.overview) }}</span> 
                              <span class="fs-1rem light color-gray">{{ v.program }} - {{ v.country }}</span> 
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <span class="fs-1rem">We don't have any results for Run Event</span>
                        </div>
                      </div>
                    </div>
                    <hr v-if="resultRunEvents != null && resultSearch != null">
                    <button :type="inputSearch ? 'submit' : 'button'" class="main-button linear-primary mt-1 w-100" :class="inputSearch ? '' : 'disabled'">
                      <span>Search</span>
                    </button>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center my-4">
              <div class="close-popup pointer" @click="closePopup">
                <i class="ri-close-line"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import GlobalVue from "@/libs/Global.vue"
import RdgButton from "./atoms/RdgButton.vue"

export default {
  extends: GlobalVue,
  name: "WebHeader",
  data(){
    return{
      scrollPosition: null,
      isBlack: false,
      childRunEvent: [
        "RunEvents",
        "EventDetail",
        "CheckAvailability",
        "DetailPackage",
      ],
      childNewsArticles: [
        "NewsArticles",
        "NewsArticlesCategory",
        "NewsArticlesTag",
        "NewsArticlesType",
        "NewsArticlesDetail",
        "NewsArticlesTag"
      ],
      childMyPurchase: [
        "DetailTransaction",
        "DetailTransactionPrivate",
        "TransactionsHistory"
      ],
      isWebPSupport: false,
      isAnnouncement: true,
    }
  },
  props: [
    "isOutline"
  ],
  components: {
    RdgButton
  },
  computed: {
    ...mapFields([
      "userToken",
      "user"
    ]),

    ...mapFields("home", [
      "inputSearch",
      "resultSearch",
      "resultRunEvents",
      "totalNewsArticles",
      "totalRunEvents"
    ]),

    ...mapFields("runevents", [
      "refSignIn"
    ]),

    ...mapFields("footer", [
      "announcement"
    ])
  },
  mounted() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
    window.addEventListener('scroll', this.updateScroll)
    this.isMobile()
    this.updateScroll()
  },

  methods: {
    ...mapActions("home", [
      "doSearchNewsArticles",
      "doSearchRunEvents",
      "countNewsArticles",
      "countRunEvents"
    ]),

    removeAnnouncement() {
      this.isAnnouncement = false
    },

    getParagraphFromCK(str){
      if(str){
        let html = str

        html = html.replace(/&nbsp;/g, ' ')

        let div = document.createElement("div")

        div.innerHTML = html

        let textParagraph = div.textContent || div.innerText || ""

        let searchString = textParagraph.toLowerCase()

        let pattern = new RegExp(`([\w\\S]+ ){0,5}((\\S+)?(${this.inputSearch})(\\S+)?)( [\w\\S]+){0,5}`, "gi")

        // Ascending search
        let searchContentASC = searchString.match(pattern)

        if (searchContentASC !== null) {
          // searched content
          let matched = searchContentASC[0]

          let start = searchString.indexOf(matched)

          let end = start + matched.length

          let res = textParagraph.substring(start, end)

          // match not space after period
          let matchPeriod = res.match(/\.(?!\s)/g)

          if (matchPeriod !== null) {
            res = res.replace(/\.(?!\s)/g, '. ');
          }

          return res  + '...'
        }
      }
    },

    updateScroll() {
      this.scrollPosition = window.scrollY

      if(this.isMobile()){
        this.isBlack = true
      } else{
        if(this.scrollPosition > 40){
          this.isBlack = true
        } else {
          this.isBlack = false
        }
      }

    },

    async doSearch() {
      try{
        if(this.inputSearch){
          await this.doSearchNewsArticles()
          await this.doSearchRunEvents()
        } else {
          this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
        }
      } catch(err) {
        return err
      }
    },

    clearInputSearch(){
      this.$set(this, 'inputSearch', null)
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
    },

    pushToRunEvent(slug){
      this.$router.push({name: 'EventDetail', params: {pageSlug: slug}})
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
      this.closePopup()
    },

    pushToNewsArticles(slug){
      this.$router.push({name: 'NewsArticlesDetail', params: {pageSlug: slug}})
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
      this.closePopup()
    }
  },
  watch: {
    'inputSearch': {
      handler(v) {
        if ((this.resultSearch || []).length > 0 || (this.resultRunEvents || []).length > 0) {
          this.$store.commit("home/CLEAR_SEARCH_RESULTS")
        }
      }
    }
  }
};
</script>
