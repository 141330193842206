import Vue from "vue"
import Vuex from "vuex"

import { getField, updateField } from "vuex-map-fields"

import HomePage from "./module/home"
import NewsArticles from "./module/newsarticles"
import RunEvents from "./module/runevents"
import Calendar from "./module/calendar"
import MyBooking from "./module/mybooking"
import Profile from "./module/profile"
import Gallery from "./module/gallery"
import About from "./module/about"
import Legal from "./module/legal"
import Contact from "./module/contact"
import Footer from "./module/footer"

import Gen from "../libs/Gen"
import { http } from "../config/http"
import auth from "./module/auth"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    production: process.env.VUE_APP_MODE == "production",
    isDev: process.env.VUE_APP_IS_DEV == "1",
    apiUrl: process.env.VUE_APP_API_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    loading: {
      screen: false,
      data: false,
    },
    mrGender: ["Male", "Female"],
    staticseo: null,
    userToken: null,
    user: {},
    data: {},
    relation: null,
    closeModal: null,
  },
  getters: {
    getField,
    authenticated(state) {
      return state.userToken
    },
  },
  mutations: {
    updateField,
    updateUser(state, data = {}) {
      try {
        if (!Object.prototype.hasOwnProperty.call(data, "user")) {
          return
        }

        let arr = data.user

        arr.registrant_dial_code = data.user_address.registrant_dial_code
        arr.registrant_address = data.user_address.registrant_address
        arr.registrant_address_1 = data.user_address.registrant_address_1
        arr.registrant_address_2 = data.user_address.registrant_address_2
        arr.registrant_address_3 = data.user_address.registrant_address_3
        arr.registrant_city = data.user_address.registrant_city
        arr.registrant_district = data.user_address.registrant_district
        arr.registrant_subdistrict = data.user_address.registrant_subdistrict
        arr.registrant_zip_code = data.user_address.registrant_zip_code
        arr.registrant_state = data.user_address.registrant_state
        arr.registrant_citizenship_fo = data.user_address.citizenship_name
        arr.registrant_country = data.user_address.registrant_country
        arr.registrant_country_of_residence_flag = data.user_address.registrant_country_of_residence_flag
        arr.registrant_country_of_residence_fo = data.user_address.registrant_country_of_residence_fo

        state.user = arr
      } catch (error) {
        return error
      }
    },
    updateUserToken(state, data = null, test) {
      state.userToken = data
      Gen.setCookie("userAuth", data, 30 * 3)
    },
    logoutUserToken(state, data = null) {
      state.userToken = data
      state.user = {}
      Gen.setCookie("userAuth", "")
    },
    SET_STATIC_SEO(state, data = {}) {
      state.staticseo = data
    },
  },
  actions: {
    async watchMeta({ commit }, path) {
      try {
        const res = await http.get("/meta", {
          headers: Gen.apiHeader(),
          params: {
            uri_path: path,
          },
        })

        commit("SET_STATIC_SEO", res.data[0])
      } catch (err) {
        return err
      }
    },

    async watchAuth({ commit }) {
      if (
        Gen.getCookie("userAuth") === "" ||
        Gen.getCookie("userAuth") === null
      ) {
        return
      }
      commit("updateUserToken", Gen.getCookie("userAuth"))
      try {
        const token = Gen.getCookie("userAuth")
        let resp = await http.get("/auth/profile", {
          headers: Gen.apiHeaderAuth(token),
        })
        resp = resp.data
        if (resp.status == "success") {
          commit("updateUserToken", resp.data.token)
          commit("updateUser", resp.data)
        } else {
          commit("updateUser", {})
        }
      } catch (error) {
        commit("updateUser", {})
        commit("logoutUserToken", null)
      }
    },
  },
  modules: {
    home: HomePage,
    newsarticles: NewsArticles,
    profile: Profile,
    gallery: Gallery,
    runevents: RunEvents,
    calendar: Calendar,
    mybooking: MyBooking,
    about: About,
    legal: Legal,
    contact: Contact,
    footer: Footer,
    auth: auth,
  },
})
