import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "@/libs/Gen"
import router from "../../router"

export default {
  namespaced: true,
  state: {
    hero: {},
    setFilter: {},
    galleryData: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_API_DATA(state, data) {
      state.hero = data.hero.data
      state.galleryData = data.gallery
      state.setFilter = data.filter
    },
  },
  actions: {
    async getData({ state, commit, rootState }, params = {}) {
      try {
        rootState.loading.screen = true

        const data = await http.get("/gallery", {
          headers: Gen.apiHeader(),
          params: {
            ...params,
          },
        })

        commit("SET_API_DATA", data.data)

        if (data.data.gallery.data.length == 0 && params.category === null) {
          router.push({ name: "Gallery" }).catch(() => {})
        }

        if (data.data.gallery.current_page > data.data.gallery.last_page) {
          router.push({ name: "Gallery" }).catch(() => {})
        }

        rootState.staticseo = data.data.staticseo
      } catch (err) {
        router.push({ name: "Gallery" }).catch(() => {})
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
