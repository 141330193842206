<template>
  <div>
    <header class="header-black isn-half-banner">
      <b-navbar toggleable="lg" class="container py-3">
        <router-link to="/" class="text-capitalize text-white navbar-brand
            text-white
            font-weight-bold
            d-flex
            align-items-center">
          <picture>
            <source :srcset="`${baseUrl}/assets/image/logo/logoblack.webp`" type="image/webp">
            <img :src="`${baseUrl}/assets/image/logo/logoblack.png`" alt="Logo RunDeGlobe" class="w-75">
          </picture>
          <h1 class="d-none">RunDeGlobe</h1>
        </router-link>
        <b-navbar-toggle target="nav-collapse">
          <i class="ri-menu-4-line"></i>
        </b-navbar-toggle>

         <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav m-auto">
            <li class="nav-item">
              <router-link :to="{ name: 'RunEvents' }" class="nav-link text-capitalize text-white"
                >run events</router-link
              >
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Gallery' }" class="nav-link text-capitalize text-white"
                >gallery</router-link
              >
            </li>
            <li class="nav-item" v-if="Object.keys(this.user).length !== 0">
              <router-link 
                :to="{ name: 'TransactionsHistory' }" 
                class="nav-link text-capitalize text-white"
                :class="childMyPurchase.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >My Purchases</router-link
              >
            </li>

            <li class="nav-item">
              <router-link 
                :to="{ name: 'NewsArticles' }" 
                class="nav-link text-capitalize text-white"
                :class="childNewsArticles.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                >Articles</router-link
              >
            </li>

            <li class="nav-item active">
              <router-link :to="{ name: 'About' }" class="nav-link text-capitalize text-white"
                >About Us</router-link
              >
            </li>
            
            <li class="nav-item">
              <router-link :to="{name: 'Contact'}" class="nav-link text-capitalize text-white"
                >Contact Us</router-link
              >
            </li>

          </ul>

          <div @click="searchNewsArticle" class="search-sec black">
            <i class="ri-search-line icon-search"></i>
          </div>

          <!-- <button class="main-button linear-primary text-capitalize" @click="$router.push({name: 'Login'})">
            <span v-if="userToken">Profile {{userToken}}</span>
            <span v-else>Sign in</span>
          </button> -->

          <RdgButton @clicked="$router.push({ name: 'AccountInformation'}).catch(() => {})" type="primary" text="Profile" v-if="userToken"/>
          <RdgButton @clicked="$router.push({ name: 'Login'}).catch(() => {})" type="primary" text="Sign In" v-else/>
         </b-collapse>
      </b-navbar>
    </header>
    <div class="popup-search">
      <div class="container mt-5">
        <div class="row d-flex flex-column justify-content-center align-items-center">
            <div class="col-lg-6">
              <div class="card">
                <h3 :class="isMobile() ? 'text-center border-bottom-1rem' : 'border-bottom-1rem'">Search </h3>
                <!-- <span class="light fs-1rem" :class="isMobile() ? 'text-center' : ''">We have {{ totalNewsArticles }} news articles & {{ totalRunEvents }} Run Events in RunDeGlobe</span> -->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form class="form-sec" @submit.prevent="handleSubmit(doSearch)">
                    <div class="input-box mt-4"> 
                      <input type="text" v-model="inputSearch" id="inputPopupsearch" class="form-control" placeholder="e.g BMW Berlin Marathon"> <i class="fa fa-search" ></i> 
                      <i class="ri-close-fill" @click="clearInputSearch" v-if="inputSearch"></i>
                    </div>
                    <div class="content">

                      <span v-if="inputSearch" class="fs-1rem">Search RunDeGlobe for "{{ inputSearch }}"</span>
                      <hr>
                      <div class="my-4" v-if="resultSearch != null">
                        <p>News Articles</p>
                        <div v-if="(resultSearch|| {}).length != 0">
                          <div class="list border-bottom" v-for="(v, k) in resultSearch" :key="k">
                            <div class="d-flex flex-column ml-3"> 
                              <a @click="pushToNewsArticles(v.slug)" class="heading-search text-main"><span v-html="v.title"></span></a>
                              <span class="fs-1rem light ellipsis">{{ getParagraphFromCK(v.description) }}</span> 
                              <span class="fs-1rem light color-gray">{{ v.type }} - {{ v.author_name }}</span> 
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <span class="fs-1rem">We don't have any news or articles with that title</span>
                        </div>
                      </div>
                      <div class="my-4" v-if="resultRunEvents != null">
                        <p>Run Events</p>
                        <div v-if="(resultRunEvents || {}).length != 0">
                          <div class="list border-bottom" v-for="(v, k) in resultRunEvents" :key="k">
                            <div class="d-flex flex-column ml-3"> 
                              <a @click="pushToRunEvent(v.slug)" class="text-main"><span>{{ v.name }}</span></a>
                              <span class="fs-1rem light ellipsis">{{ getParagraphFromCK(v.overview) }}</span> 
                              <span class="fs-1rem light color-gray">{{ v.program }} - {{ v.country }}</span> 
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <span class="fs-1rem">We don't have any results for Run Event</span>
                        </div>
                      </div>
                    </div>
                    <hr v-if="resultRunEvents != null && resultSearch != null">
                    <button :type="inputSearch ? 'submit' : 'button'" class="main-button linear-primary mt-1 w-100" :class="inputSearch ? '' : 'disabled'">
                      <span>Search</span>
                    </button>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center my-4">
              <div class="close-popup pointer" @click="closePopup">
                <i class="ri-close-line"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import GlobalVue from "@/libs/Global.vue"
import RdgButton from "@/components/atoms/RdgButton.vue"

export default {
  extends: GlobalVue,
  name: "WebHeaderNoBanner",
  data(){
    return{
      scrollPosition: null,
      childNewsArticles: [
        "NewsArticles",
        "NewsArticlesCategory",
        "NewsArticlesTag",
        "NewsArticlesType",
        "NewsArticlesDetail",
        "NewsArticlesTag"
      ],
      childMyPurchase: [
        "MyBooking",
        "DetailTransaction",
        "DetailTransactionPrivate",
        "TransactionsHistory"
      ],
    }
  },
  components: {
    RdgButton
  },
  computed: {
    ...mapFields([
      "userToken",
      "user"
    ]),
    ...mapFields("home",[
      "inputSearch",
      "resultSearch",
      "resultRunEvents",
      "totalNewsArticles",
      "totalRunEvents"
    ])
  },
  mounted() {
    this.isMobile()
  },
  methods: {
    ...mapActions("home", [
      "doSearchNewsArticles",
      "doSearchRunEvents",
    ]),

    getParagraphFromCK(str){
      if(str){
        let html = str

        html = html.replace(/&nbsp;/g, ' ')

        let div = document.createElement("div")

        div.innerHTML = html

        let textParagraph = div.textContent || div.innerText || ""

        let searchString = textParagraph.toLowerCase()

        let pattern = new RegExp(`([\w\\S]+ ){0,5}((\\S+)?(${this.inputSearch})(\\S+)?)( [\w\\S]+){0,5}`, "gi")

        // Ascending search
        let searchContentASC = searchString.match(pattern)

        if (searchContentASC !== null) {
          // searched content
          let matched = searchContentASC[0]

          let start = searchString.indexOf(matched)

          let end = start + matched.length

          let res = textParagraph.substring(start, end)

          // match not space after period
          let matchPeriod = res.match(/\.(?!\s)/g)

          if (matchPeriod !== null) {
            res = res.replace(/\.(?!\s)/g, '. ');
          }

          return res  + '...'
        }
      }
    },

    async doSearch() {
      try{
        if(this.inputSearch){
          await this.doSearchNewsArticles()
          await this.doSearchRunEvents()
        } else {
          this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
        }
      } catch(err) {
        return err
      }
    },

    clearInputSearch(){
      this.$set(this, 'inputSearch', null)
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
    },

    pushToRunEvent(slug){
      
      this.$router.push({name: 'EventDetail', params: {pageSlug: slug}})
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
      this.closePopup()
    },

    pushToNewsArticles(slug){
      this.$router.push({name: 'NewsArticlesDetail', params: {pageSlug: slug}})
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
      this.closePopup()
    }
  },
  watch: {
    'inputSearch': {
      handler(v) {
        if ((this.resultSearch || []).length > 0 || (this.resultRunEvents || []).length > 0) {
          this.$store.commit("home/CLEAR_SEARCH_RESULTS")
        }
      }
    }
  }
};
</script>
