import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"

import Gen from "@/libs/Gen"

export default {
  namespaced: true,
  state: {
    aboutContentPage: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_ABOUT_CONTENT(state, data) {
      state.aboutContentPage = data
    },
  },
  actions: {
    async getAboutContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/about", {
          headers: Gen.apiHeader(),
        })

        commit("SET_ABOUT_CONTENT", resp.data)
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
