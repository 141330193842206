<template>
  <div>
    <div class="navigation_bar">
      <nav class="links_navigation" style="--items: 4;">
        <router-link :to="{name: 'Home'}">
          <div class="link_menu">
            <i class="ri-home-line"></i>
            <span>Home</span>
          </div>
        </router-link>
        <router-link 
          :to="{name: 'RunEvents'}"
          :class="childRunEvent.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
        >
          <div class="link_menu">
            <i class="ri-run-line"></i>
            <span>Run Events</span>
          </div>
        </router-link>
  
        <router-link 
          :to="{name: 'TransactionsHistory'}"
          :class="childMyPurchase.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
        >
          <div class="link_menu">
            <i class="ri-booklet-line"></i>
            <span>My Purchases</span>
          </div>
        </router-link>
        <router-link 
          :to="{name: 'Dashboard'}" 
          :class="childProfile.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
          v-if="userToken"
        >
          <div class="link_menu">
            <i class="ri-user-line"></i>
            <span>Profile</span>
          </div>
        </router-link>
        <router-link :to="{name: 'Login'}" v-else>
          <div class="link_menu">
            <i class="ri-user-line"></i>
            <span :href="baseUrl + '/auth/sign-in'">{{ userToken ? "Profile" : "Sign In"}}</span>
          </div>
        </router-link>
        <span class="line"></span>
      </nav>
    </div>
  </div>
</template>

<script>

import { mapFields } from "vuex-map-fields"

export default{
  name: "MobileNavigation",
  data() {
    return {
      childProfile: [
        "AccountInformation",
        "Dashboard",
        "ChangePassword"
      ],
      childMyPurchase: [
        "DetailTransaction",
        "DetailTransactionPrivate",
        "TransactionsHistory"
      ],
      childRunEvent: [
        "RunEvents",
        "EventDetail",
        "CheckAvailability",
        "DetailPackage",
      ],
    }
  },
  computed: {
    ...mapFields([
      "userToken",
      "baseUrl"
    ])
  },
}

</script>