<template>
  <footer id="footer" class="background-white">
    <div class="container">
      <div :class="isMobile() ? 'row dun' : 'row dun'">
        <div class="col-lg-3 my-3">
          <router-link to="/">
            <picture>
              <source :srcset="baseUrl+'/assets/image/logo/logowhite.webp'" type="image/webp">
              <img :src="baseUrl+'/assets/image/logo/logowhite.png'" alt="Logo RunDeGlobe" :class="isOnlyMobile() ? 'w-50' : !isMobile() ? 'w-75' : 'w-25'">
            </picture>
          </router-link>
          <p class="mt-2 text-white light fs-1rem">{{ (content.data || {}).description }}</p>
        </div>
        <div class="col-lg-2 col-md-3 col-5 d-flex flex-column my-3">
          <p>Menu</p>
          <router-link :to="{name: 'About'}" class="text-capitalize light fs-1rem" :class="!isMobile() ? 'my-2' : 'my-2'">about us</router-link>
          <router-link :to="{name: 'RunEvents'}" class="text-capitalize light fs-1rem" :class="!isMobile() ? 'my-2' : 'my-2'">run events</router-link>
          <router-link :to="{name: 'Gallery'}" class="text-capitalize light fs-1rem" :class="!isMobile() ? 'my-2' : 'my-2'">gallery</router-link>
          <router-link :to="{name: 'Contact'}" class="text-capitalize light fs-1rem" :class="!isMobile() ? 'my-2' : 'my-2'">contact us</router-link>
          <router-link :to="{name: 'NewsArticles'}" class="text-capitalize light fs-1rem" :class="!isMobile() ? 'my-2' : 'my-2'">Articles</router-link>
        </div>
        <div class="col-lg-3 col-md-3 col-7 mt-3 text-white">
          <p>Contact Us</p>
          <div :class="isMobile() ? 'd-flex flex-column align-items-center' : ''">
            <div class="d-flex my-2" :class="isMobile() ? 'w-100' : ''">
              <i class="ri-mail-line mr1-important" :class="isMobile() ? '' : ''"></i>
              <a target="_blank" :href="'mailto:' + (contact.data || {}).email_address" class="light fs-1rem text-wrap" :class="isMobile() ? 'w-75' : ''">{{ (contact.data || {}).email_address }}</a>
            </div>
            <div class="d-flex my-2" :class="isMobile() ? 'w-100' : ''">
              <i class="ri-phone-line mr1-important" :class="isMobile() ? '' : ''"></i>
              <a target="_blank" :href="'tel:' + (contact.data || {}).mobile_phone" class="light fs-1rem text-wrap" :class="isMobile() ? 'w-100' : ''">{{ (contact.data || {}).mobile_phone }}</a>
            </div>
            <div class="d-flex my-2" :class="isMobile() ? 'w-100' : ''">
              <i class="ri-map-2-line mr1-important" :class="isMobile() ? '' : ''"></i>
              <div :class="isMobile() ? 'w-100' : ''">
                <a target="_blank" :href="(address.data || {}).link">
                  <span
                    class="fs-1rem light d-flex flex-column text-wrap" 
                    :class="isMobile() ? 'w-100' : ''"
                    v-for="(address, index) in (address.data || {}).description" 
                    :key="index"
                  >
                    {{address}}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 my-3" :class="isMobile() ? 'my-3' : ''">
          <p>Our Partners</p>
          <div class="d-flex flex-wrap" v-if="!isMobile()" :class="isMobile() ? '' : ''">
            <div class="card-partner-sm" v-for="(v, k) in partners" :key="k">
              <a :href="v.url" target="_blank">
                <picture>
                  <source :srcset="uploader(v.image)" type="image/webp">
                  <img :src="uploader(v.fallback_image)" :alt="v.alt_image">
                </picture>
              </a>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-3 col-md-6" v-for="(v, k) in partners" :key="k">
              <div class="card-partner-sm">
                <a :href="v.url" target="_blank">
                  <picture>
                    <source :srcset="uploader(v.image)" type="image/webp">
                    <img :src="uploader(v.fallback_image)" :alt="v.alt_image">
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 my-3" :class="isMobile() ? 'my-3' : ''">
          <p>See Us On</p>
          <div class="ban-share">
            <div class="share-soc ml-4">
              <div class="d-flex flex-wrap">
                <div class="icon-share bg-blue"  :class="isMobile() ? 'mx-3' : ''" v-if="(socialmedia.data || {}).facebook">
                  <a target="_blank" :href="((socialmedia.data || {}).facebook || {}).url" aria-label="facebook-link">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </div>
                <div class="icon-share bg-blue-twitter" :class="isMobile() ? 'mx-3' : ''" v-if="(socialmedia.data || {}).twitter">
                  <a target="_blank" :href="((socialmedia.data || {}).twitter || {}).url" aria-label="twitter-link">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </div>
                <div class="icon-share bg-purple-instagram" :class="isMobile() ? 'mx-3' : ''" v-if="(socialmedia.data || {}).instagram">
                  <a target="_blank" :href="((socialmedia.data || {}).instagram || {}).url" aria-label="instagram-link">
                    <i class="ri-instagram-line"></i>
                  </a>
                </div>
                <div class="icon-share bg-red-pinterest" :class="isMobile() ? 'mx-3' : ''" v-if="(socialmedia.data || {}).pinterest">
                  <a target="_blank" :href="((socialmedia.data || {}).pinterest || {}).url" aria-label="pinterest-link">
                    <i class="ri-pinterest-line"></i>
                  </a>
                </div>
                <div class="icon-share bg-darkblue-tumblr" :class="isMobile() ? 'mx-3' : ''" v-if="(socialmedia.data || {}).tumblr">
                  <a target="_blank" :href="((socialmedia.data || {}).tumblr || {}).url" aria-label="tumblr-link">
                    <i class="ri-tumblr-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="border-grey my-3">
        <div class="col-md-9 d-flex align-items-center" :class="isOnlyMobile() ? 'justify-content-center text-center' : ''">
          <p class="light text-white fs-1rem">Copyright © {{ year }} RunDeGlobe. All Rights Reserved</p>
        </div>
        <div class="col-md-3">
          <div class="d-flex" :class="!isMobile() ? 'justify-content-end' : 'justify-content-center'">
            <router-link :to="{name: 'TermsConditions'}" class="text-capitalize light fs-1rem mr1-important" :class="!isMobile() ? 'my-2' : 'my-2'">terms & conditions</router-link>
            <router-link :to="{name: 'PrivacyPolicy'}" class="text-capitalize light fs-1rem" :class="!isMobile() ? 'my-2' : 'my-2'">privacy policy</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import GlobalVue from "@/libs/Global.vue"

export default {
  extends: GlobalVue,
  name: "WebFooter",
  data() {
    return {
      isWebPSupport: false
    }
  },
  computed: {
    ...mapFields("home", [
      "partners"
    ]),
    ...mapFields("footer", [
      "address",
      "socialmedia",
      "contact",
      "content",
      "partners",
      "year",
      "announcement"
    ])
  },
  methods: {
    ...mapActions("footer", [
      "getFooterContent"
    ])
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result;
    });
    this.getFooterContent();
  }
};
</script>
