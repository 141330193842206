<template>
  <div>
    <!-- <div class="bullet1"></div>
    <div class="bullet2">
      <div class="subbullet2_1"></div>
    </div> -->
    <HeaderNavigation 
      :logo="`${baseUrl}/assets/image/logo/logoblack.png`"
      v-if="isMobile()"
    />
    <div class="bg-notfound">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center minheight_100">
          <div class="col-md-6">
            <div class="not_found text-center">
              <h2 class="mt-3">
                404
                <div class="bullet"></div>
              </h2>
              <h3 class="mb-4">Page Not Found</h3>
              <span>
                Maybe this page used to exist or you spelled something wrong. 
                Chances are you spelled something wrong, so can you double check the URL?
              </span>
              <div class="d-flex justify-content-center mt-5">
                <RdgButton @clicked="$router.push({ name: 'Home' })" type="primary-icon" text="Return Home"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNavigation from "@/layouts/HeaderNavigation";
import RdgButton from "@/components/atoms/RdgButton.vue"
import { mapFields } from "vuex-map-fields"
import GlobalVue from "@/libs/Global.vue"

export default {
  extends: GlobalVue,
  name: "NotFound",
  components: {
    HeaderNavigation,
    RdgButton
  },
  computed: {
    ...mapFields([
      "baseUrl"
    ])
  }
}
</script>
