import store from "@/store"
import Config from "@/libs/Config"
import { http } from "@/config/http"

let CryptoJS = require("crypto-js")

const Gen = {
  apiHeader() {
    return {
      "X-API-KEY":
        "Basic V0ItUkRHLXNlcnZlci1kYTI4MDllY2NlYxRheB7jQzYzYzNThhYWM3YTI3MmM3MDM6",
      "X-Frame-Options": "sameorigin",
      "X-XSS-Protection": "1; mode=block",
      "X-Content-Type-Option": "nosniff",
      "Content-Security-Policy": "script-src 'self'",
      "Strict-Transport-Security": "max-age=31536000;includeSubDomains",
    }
  },
  apiHeaderAuth(token) {
    return {
      "X-API-KEY":
        "Basic V0ItUkRHLXNlcnZlci1kYTI4MDllY2NlYxRheB7jQzYzYzNThhYWM3YTI3MmM3MDM6",
      "X-Frame-Options": "sameorigin",
      "X-XSS-Protection": "1; mode=block",
      "X-Content-Type-Option": "nosniff",
      "Content-Security-Policy": "script-src 'self'",
      "Strict-Transport-Security": "max-age=31536000;includeSubDomains",
      "X-Authorization": `Bearer ${token}`,
    }
  },
  apiHeaderAuthFormData(token) {
    return {
      "X-API-KEY":
        "Basic V0ItUkRHLXNlcnZlci1kYTI4MDllY2NlYxRheB7jQzYzYzNThhYWM3YTI3MmM3MDM6",
      "X-Frame-Options": "sameorigin",
      "X-XSS-Protection": "1; mode=block",
      "X-Content-Type-Option": "nosniff",
      "Content-Security-Policy": "script-src 'self'",
      "Strict-Transport-Security": "max-age=31536000;includeSubDomains",
      "X-Authorization": `Bearer ${token}`,
    }
  },
  apiRest(path, config = {}, method = "get", responType = "json") {
    let options = {
      headers: {
        ...Gen.apiHeader(),
      },
      method: method,
      url: path,
      responseType: responType,
    }

    let data = {}
    let params = {}
    let headers = {}

    if (config.data) {
      // options.method = method
      data = Object.assign(data, config.data)
      data = {
        data: JSON.stringify({
          payload: Gen.CryptoJSAesEncrypt(
            Config.encrypt_key,
            JSON.stringify(data)
          ),
        }),
      }
    }

    if (config.params) params = Object.assign(params, config.params)
    if (config.headers) headers = Object.assign(headers, config.headers)

    // params = Object.assign({ token: Gen.getCookie("userAuth") || ""}, params || {})

    if (Object.prototype.hasOwnProperty.call(config, "data"))
      options.data = data
    if (Object.prototype.hasOwnProperty.call(config, "headers"))
      options.headers = headers
    if (Object.prototype.hasOwnProperty.call(config, "params"))
      options.params = params

    return http(options)
  },
  CryptoJSAesEncrypt(pass, value) {
    var salt = CryptoJS.lib.WordArray.random(256)
    var iv = CryptoJS.lib.WordArray.random(16)
    var key = CryptoJS.PBKDF2(pass, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999,
    })
    var encrypted = CryptoJS.AES.encrypt(value, key, { iv: iv })
    var data = {
      ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iv: CryptoJS.enc.Hex.stringify(iv),
    }
    return JSON.stringify(data)
  },
  objectToQuery(obj) {
    const results = []
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        Object.entries(value).forEach(([key, value]) => {
          results.push(`${key}=${value}`)
        })
      } else {
        results.push(`${key}=${value}`)
      }
    })
    return results.join("&")
  },
  getCookie(name) {
    var nameEQ = name + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  setCookie(name, value, num = 1, type = "day") {
    try {
      var expires = ""

      var cookie_domain = ""
      if (location.hostname != "localhost") {
        var a = document.createElement("a")
        a.href = store.url
        cookie_domain = a.hostname
      }

      var date = new Date()
      if (type == "day") {
        date.setTime(date.getTime() + num * 24 * 60 * 60 * 1000)
        expires = "; expires=" + date.toUTCString()
      } else if (type == "minute") {
        date.setTime(date.getTime() + num * 60 * 1000)
        expires = "; expires=" + date.toUTCString()
      }

      document.cookie =
        name +
        "=" +
        (value || "") +
        expires +
        "; path=/; domain=" +
        cookie_domain
    } catch (err) {
      console.log(err)
    }
  },
  delay: (function () {
    var timer = 0
    return function (callback, ms) {
      clearTimeout(timer)
      timer = setTimeout(callback, ms)
    }
  })(),
  interval(callback, speed, timeout) {
    var time = 0
    var i = setInterval(() => {
      time += speed
      try {
        callback()
      } catch (e) {
        // statement
      }
      if (time == timeout) clearInterval(i)
    }, speed)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve
      }, timeout)
    })
  },
  randomStr(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  setMeta(data) {
    let lengthTitle = (data || {}).title.length
    let title =
      lengthTitle > 49 ? (data || {}).title.limitChar(50) : (data || {}).title

    document.querySelector("title").html((data || {}).title)
    document.querySelector("[property='og:title']").attr("content", title)
    document
      .querySelector("[name='twitter:title']")
      .attr("content", (data || {}).title)

    if ((data || {}).description) {
      let ogDesc = (data || {}).description
        .replaceAll(/["']/gm, "")
        .replace(/<[^>]*>?/gm, "")
      ogDesc =
        (data || {}).description.length > 100
          ? (data || {}).description.limitChar(100)
          : (data || {}).description
      document
        .querySelector("[name='description']")
        .attr("content", (data || {}).description)
      document
        .querySelector("[property='og:description']")
        .attr("content", ogDesc)
      document
        .querySelector("[property='og:image']")
        .attr("content", (data || {}).image.url)
      document
        .querySelector("[property='og:image:width']")
        .attr("content", (data || {}).image.width)
      document
        .querySelector("[property='og:image:height']")
        .attr("content", (data || {}).image.height)

      document
        .querySelector("[name='twitter:image']")
        .attr("content", (data || {}).image.url)
      document
        .querySelector("[name='twitter:description']")
        .attr("content", (data || {}).description)
    }

    if ((data || {}).keyword) {
      let keyword = (data || {}).keyword
      if ($.type(keyword) != "string") keyword = keyword.join(",")

      document.querySelector("[name='keywords']").attr("content", keyword)
    }

    document
      .querySelector("[name='alternate']")
      .attr("href", (data || {}).url || "")
    document
      .querySelector("[name='canonical']")
      .attr("href", (data || {}).url || "")
  },
}

export default Gen
