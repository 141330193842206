import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "../../libs/Gen"

export default {
  namespaced: true,
  state: {
    citizenshipData: null,
    countryCodeData: [],
    provinceData: [],
    citiesData: [],
    districtData: [],
    subdistrictData: [],
    inputChangeProfile: {},
    inputChangePassword: {
      new_password: "",
      confirm_new_password: "",
    },
    inputChangeProfileFoto: null,
    registeredEvents: {},
    pastEvents: {},
    gender: [
      { value: "M", text: "Male" },
      { value: "F", text: "Female" },
      { value: "O", text: "Other" },
    ],
    nationality: [
      { value: "WNI", text: "Indonesian Citizen" },
      { value: "WNA", text: "Foreigner" },
    ],
    bloodtype: [
      { value: "A+", text: "A+" },
      { value: "AB+", text: "AB+" },
      { value: "B+", text: "B+" },
      { value: "O+", text: "O+" },
      { value: "A-", text: "A-" },
      { value: "AB-", text: "AB-" },
      { value: "B-", text: "B-" },
      { value: "O-", text: "O-" },
    ],
    dateAPI: null,
    filterCountry: null
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_CITIZENSHIP_DATA(state, data) {
      state.citizenshipData = data
    },

    SET_COUNTRY_CODE_DATA(state, data) {
      state.countryCodeData = data
    },

    SET_PROVINCE_DATA(state, data) {
      state.provinceData = data
    },

    CLEAR_INPUT_CHANGE_PASSWORD(state) {
      state.inputChangePassword.new_password = ""
      state.inputChangePassword.confirm_new_password = ""
    },

    SET_CITIES_DATA(state, data) {
      state.citiesData = data
    },

    SET_DISTRICT_DATA(state, data) {
      state.districtData = data
    },

    SET_SUBDISTRICT_DATA(state, data) {
      state.subdistrictData = data
    },

    SET_INFORMATION_ACCOUNT(state, data) {
      try {
        if (!Object.prototype.hasOwnProperty.call(data, "user")) {
          return
        }

        let arr = data.user

        arr.registrant_citizenship_flag = data.user.registrant_citizenship_flag
        arr.registrant_citizenship = data.user.registrant_citizenship ? parseInt(data.user.registrant_citizenship) : data.user.registrant_citizenship
        arr.registrant_country_of_residence = parseInt(data.user_address.registrant_country_of_residence)
        arr.registrant_country_of_residence_flag = data.user_address.registrant_country_of_residence_flag
        arr.registrant_address = data.user_address.registrant_address
        arr.registrant_address_1 = data.user_address.registrant_address_1
        arr.registrant_address_2 = data.user_address.registrant_address_2
        arr.registrant_address_3 = data.user_address.registrant_address_3
        arr.registrant_city = data.user_address.registrant_city
        arr.registrant_district = data.user_address.registrant_district
        arr.registrant_subdistrict = data.user_address.registrant_subdistrict
        arr.registrant_zip_code = data.user_address.registrant_zip_code
        arr.registrant_state = data.user_address.registrant_state
        arr.registrant_country = data.user_address.registrant_country
        arr.registrant_phone = data.user.registrant_phone
        arr.registrant_citizenship_fo = data.user_address.citizenship_name

        state.inputChangeProfile = arr
      } catch (error) {
        return error
      }
    },

    SET_REGISTERED_EVENTS(state, data) {
      state.registeredEvents = data
    },

    SET_DATE(state, data) {
      state.dateAPI = data
    },
  },
  actions: {
    async getProvince({ commit }) {
      try {
        const res = await http.get("/province", {
          headers: Gen.apiHeader(),
        })
        commit("SET_PROVINCE_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getCities({ commit }, state) {
      try {
        const res = await http.get("/cities", {
          headers: Gen.apiHeader(),
          params: {
            state: state,
          },
        })

        commit("SET_CITIES_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getCitizenship({ commit }, city) {
      try {
        const res = await http.get("/citizenship", {
          headers: Gen.apiHeader(),
        })

        commit("SET_CITIZENSHIP_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getCountryCode({ commit, state, rootState }) {
      try {
        rootState.loading.data = true
        const res = await http.get("/country-code", {
          headers: Gen.apiHeader(),
          params: {
            countrycode_name: state.filterCountry
          }
        })

        commit("SET_COUNTRY_CODE_DATA", res.data.data.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async getDistrict({ commit }, city) {
      try {
        const res = await http.get("/district", {
          headers: Gen.apiHeader(),
          params: {
            city: city,
          },
        })

        commit("SET_DISTRICT_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getSubDistrict({ commit }, district) {
      try {
        const res = await http.get("/subdistrict", {
          headers: Gen.apiHeader(),
          params: {
            district: district,
          },
        })

        commit("SET_SUBDISTRICT_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getDateAPI({ commit }) {
      try {
        let resp = await http.get("/dateAPI", {
          headers: Gen.apiHeader(),
        })

        commit("SET_DATE", resp.data.data.date)
      } catch (err) {
        return err
      }
    },

    async _getAllProfile({ commit }) {
      try {
        const token = Gen.getCookie("userAuth")
        let resp = await http.get("/auth/profile", {
          headers: Gen.apiHeaderAuth(token),
        })
        commit("SET_INFORMATION_ACCOUNT", resp.data.data)
        commit("SET_DATE", resp.data.data.date)
      } catch (err) {
        commit("SET_INFORMATION_ACCOUNT", {})
      }
    },

    async getProfile({ dispatch, commit }) {
      try {
        dispatch("_getAllProfile")
      } catch (error) {
        commit("SET_INFORMATION_ACCOUNT", {})
        commit("SET_DATE", {})
      }
    },

    async updateProfile({ state, rootState }) {
      try {
        rootState.loading.screen = true
        const res = await Gen.apiRest(
          "/auth/change-profile",
          {
            data: Object.assign({}, state.inputChangeProfile),
            headers: { ...Gen.apiHeaderAuth(rootState.userToken) },
          },
          "POST"
        )
        return res
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async changePassword({ state, rootState, commit }) {
      try {
        rootState.loading.sceen = true
        const res = await Gen.apiRest(
          "/auth/change-password",
          {
            data: Object.assign({}, state.inputChangePassword),
            headers: { ...Gen.apiHeaderAuth(rootState.userToken) },
          },
          "POST"
        )

        commit("CLEAR_INPUT_CHANGE_PASSWORD")

        return res
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async changeProfileFoto({ rootState, dispatch, state }) {
      try {
        rootState.loading.screen = true

        await http.put(
          `/auth/change-foto/${state.inputChangeProfile.registrant_id}`,
          state.inputChangeProfileFoto
        )
        dispatch("_getAllProfile")
        return 200
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
