<template>
  <div>
    <section id="header-mobile">
    <div class="container-fluid">
      <div class="filter_mobile" :class="nopadding ? 'nopadding' : ''">
        <div class="sec_header_mobile d-flex">
          <div class="logo_rdg_header" v-if="logo">
            <h1 class="d-none">RunDeGlobe</h1>
            <slot name="addons"></slot>
            <router-link :to="{ name: 'Home' }">
              <picture>
                <source :srcset="`${baseUrl}/assets/image/logo/logoblack.webp`" type="image/webp">
                <img :src="`${baseUrl}/assets/image/logo/logoblack.png`" alt="Logo RunDeGlobe">
              </picture>
            </router-link>
          </div>
           <div class="addons" v-if="addons">
            <slot name="addons"></slot>
          </div>
          <div class="page_title" v-if="pageTitle">
            <h5>{{ pageTitle }}</h5>
          </div>
        </div>

        <div class="d-flex">
          <div @click="searchNewsArticle" class="search-sec black mb-2">
            <i class="ri-search-line icon-search"></i>
          </div>

          <div 
            @click="handleToProfile" class="search-sec black mb-2" 
            :class="childProfile.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
          >
            <i class="ri-user-line icon-search"></i>
          </div>
        </div>

        <label for="menu-control" class="hamburger">
          <i class="hamburger__icon"></i>
          <i class="hamburger__icon"></i>
          <i class="hamburger__icon"></i>
        </label>
      </div>
    </div>
  </section>
  
  <input type="checkbox" id="menu-control" class="menu-control">
  
  <aside class="sidebar">
    
    <nav class="sidebar__menu">
      <div class="profile-menu">
        <div class="img-profil">
          <router-link :to="{ name: 'Dashboard' }">
          <div class="img-profile-sidebar">
            <picture v-if="user.registrant_avatar">
              <source :srcset="uploader(user.registrant_fallback_avatar)" type="image/webp">
              <img :src="uploader(user.registrant_avatar)" alt="Profile Image">
            </picture>
            <picture v-else>
              <source :srcset="`${baseUrl}/assets/image/profilephoto/default.webp`" type="image/webp">
              <img :src="`${baseUrl}/assets/image/profilephoto/default.png`" alt="Profile Image">
            </picture>
          </div>
          </router-link>
        </div>
        <div class="about-profile">
          <span class="name" v-if="userToken">
            <router-link :to="{ name: 'Dashboard' }" class="color-main underline">{{ user.registrant_full_name }}</router-link>
          </span>
          <span class="name" v-else>
            <!-- <router-link :to="{ name: 'Login' }" class="color-main underline">Sign In</router-link> -->
            <RdgButton :small="true" @clicked="$router.push({ name: 'Login'}).catch(() => {})" type="primary" text="Sign In"/>
          </span>
        </div>
      </div>
      <div class="link">
        <div class="group-link">
          <div class="icon-link">
            <i class="ri-home-7-line"></i>
          </div>
          <div class="text-link">
            <router-link :to="{name: 'Home'}">Home</router-link>
          </div>
        </div>
        <div class="group-link">
          <div class="icon-link">
            <i class="ri-run-line"></i>
          </div>
          <div class="text-link">
            <router-link 
              :to="{name: 'RunEvents'}"
              :class="childRunEvent.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
            >Run Events
            </router-link>
          </div>
        </div>
        <div class="group-link" v-if="userToken">
          <div class="icon-link">
            <i class="ri-user-3-line"></i>
          </div>
          <div class="text-link w-100">
            <label for="profile" class="w-100">
              <a 
                class="d-flex align-items-center justify-content-between"
                :class="childProfile.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
              >
                <span>My Profile</span>
                <i class="ri-arrow-down-s-line"></i>
              </a>
            </label>
            <input type="checkbox" id="profile"> 

            <ul class="slide">
              <li>
                <router-link :to="{name: 'AccountInformation'}">Account Information</router-link>
              </li> 
              <li>
                <router-link :to="{name: 'ChangePassword'}">Change Password</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="group-link">
          <div class="icon-link">
            <i class="ri-image-line"></i>
          </div>
          <div class="text-link">
            <router-link :to="{name: 'Gallery'}">Gallery</router-link>
          </div>
        </div>
        <div class="group-link">
          <div class="icon-link">
            <i class="ri-article-line"></i>
          </div>
          <div class="text-link">
            <router-link 
              :to="{name: 'NewsArticles'}"
              :class="childNewsArticles.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
            >Articles
            </router-link>
          </div>
        </div>
      </div>
      <div class="link">
        <div class="secondary-group-link">
          <router-link :to="{name: 'About'}">About Us</router-link>
        </div>
        <div class="secondary-group-link">
          <router-link :to="{name: 'PrivacyPolicy'}">Privacy Policy</router-link>
        </div>
        <div class="secondary-group-link">
          <router-link :to="{name: 'TermsConditions'}">Terms & Conditions</router-link>
        </div>
        <div class="secondary-group-link">
          <router-link :to="{name: 'Contact'}">Contact Us</router-link>
        </div>
        <hr v-if="userToken">
        <div class="secondary-group-link" v-if="userToken">
          <RdgButton @clicked="$refs['modal-logout'].show()" type="primary" text="Logout"/>
        </div>
      </div>
    </nav>
    
    <label for="menu-control" class="sidebar__close"></label>
    </aside>
     <div class="popup-search">
      <div class="container mt-5">
        <div class="row d-flex flex-column justify-content-center align-items-center">
            <div class="col-md-6">
              <div class="card">
                <h3 :class="isMobile() ? 'text-center border-bottom-1rem' : 'border-bottom-1rem'">Search </h3>
                <!-- <span class="light fs-1rem" :class="isMobile() ? 'text-center' : ''">We have {{ totalNewsArticles }} news articles & {{ totalRunEvents }} Run Events in RunDeGlobe</span> -->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form class="form-sec" @submit.prevent="handleSubmit(doSearch)">
                    <div class="input-box mt-4"> 
                    <input type="text" id="inputPopupsearch" v-model="inputSearch" class="form-control" placeholder="e.g BMW Berlin Marathon">
                    <i class="ri-close-fill" @click="clearInputSearch" v-if="inputSearch"></i>
                      </div>
                      <div class="content">
                        <span v-if="inputSearch" class="fs-1rem">Search RunDeGlobe for "{{ inputSearch }}"</span>
                        <hr>
                        <div class="my-4" v-if="resultSearch != null">
                          <p>News Articles</p>
                          <div v-if="(resultSearch|| {}).length != 0">
                            <div class="list border-bottom" v-for="(v, k) in resultSearch" :key="k">
                              <div class="d-flex flex-column ml-3"> 
                                <a @click="pushToNewsArticles(v.slug)" class="heading-search text-main"><span v-html="v.title"></span></a>
                                <span class="fs-1rem light ellipsis">{{ getParagraphFromCK(v.description) }}</span> 
                                <span class="fs-1rem light color-gray">{{ v.type }} - {{ v.author_name }}</span> 
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <span class="fs-1rem">We don't have any news or articles with that title</span>
                          </div>
                        </div>
                        <div class="my-4" v-if="resultRunEvents != null">
                          <p>Run Events</p>
                          <div v-if="(resultRunEvents || {}).length != 0">
                            <div class="list border-bottom" v-for="(v, k) in resultRunEvents" :key="k">
                              <div class="d-flex flex-column ml-3"> 
                                <a @click="pushToRunEvent(v.slug)" class="text-main"><span v-html="v.name"></span></a>
                                <span class="fs-1rem light ellipsis">{{ getParagraphFromCK(v.overview) }}</span> 
                                <span class="fs-1rem light color-gray">{{ v.program }} - {{ v.country }}</span> 
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <span class="fs-1rem">We don't have any result for Run Events</span>
                          </div>
                        </div>
                      </div>
                    <hr v-if="resultRunEvents != null && resultSearch != null">
                    <button :type="inputSearch ? 'submit' : 'button'" class="main-button linear-primary mt-1 w-100" :class="inputSearch ? '' : 'disabled'">
                      <span>Search</span>
                    </button>
                    <!-- <RdgButton type="primary" :w100="true" :isPrevent="false" :isSubmit="true" class="w-100" text="Search" /> -->
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center my-4">
              <div class="close-popup pointer" @click="closePopup">
                <i class="ri-close-line"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
    <b-modal ref="modal-logout" id="modal-logout" scrollable size="md" hide-footer :centered="!isMobile()" :no-fade="isMobile()">
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <b-button class="icon-modal d-flex align-items-center" size="md" @click="close()">
          <i class="ri-close-line"></i>
          <h5 class="text-center text-dark mt-2 ml-4">Logout</h5>
        </b-button>
      </template>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-center">
              <picture class="d-flex justify-content-center">
                <source :srcset="`${baseUrl}/assets/image/static/warning.webp`" type="image/webp">
                <img :src="`${baseUrl}/assets/image/static/warning.png`" alt="Warning Icon" class="w-50 align-self-start">
              </picture>
            </div>
            <h5 class="text-center mt-5">Log out?</h5>
            <p class="text-center mt-3 mb-5">Are you sure want to logout?</p>
            <div class="d-flex align-items-center justify-content-center">
              <div>
                <b-button @click="doLogout" class="main-button lg linear-warning">Yes, Logout now</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import GlobalVue from "@/libs/Global.vue"
import RdgButton from "@/components/atoms/RdgButton.vue"

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

export default {
  extends: GlobalVue,
  name: "HeaderNavigation",
  components: {
    RdgButton
  },
  data() {
    return {
      childRunEvent: [
        "RunEvents",
        "EventDetail",
        "CheckAvailability",
        "DetailPackage",
      ],
      childNewsArticles: [
        "NewsArticles",
        "NewsArticlesCategory",
        "NewsArticlesTag",
        "NewsArticlesType",
        "NewsArticlesDetail",
        "NewsArticlesTag"
      ],
      childProfile: [
        "AccountInformation",
        "Dashboard",
        "ChangePassword"
      ],
    }
  },
  props: [
    "logo",
    "pageTitle",
    "addons",
    "eventClick",
    "routerPush",
    "nopadding",
  ],
  computed: {
    ...mapFields([
      "userToken",
      "user"
    ]),

    ...mapFields("home", [
      "inputSearch",
      "resultSearch",
      "resultRunEvents",
      "totalNewsArticles",
      "totalRunEvents"
    ])

  },
  methods: {
    ...mapActions("home", [
      "doSearchNewsArticles",
      "doSearchRunEvents",
    ]),

    getParagraphFromCK(str){
      if(str){
        let html = str

        html = html.replace(/&nbsp;/g, ' ')

        let div = document.createElement("div")

        div.innerHTML = html

        let textParagraph = div.textContent || div.innerText || ""

        let searchString = textParagraph.toLowerCase()

        let pattern = new RegExp(`([\w\\S]+ ){0,5}((\\S+)?(${this.inputSearch})(\\S+)?)( [\w\\S]+){0,5}`, "gi")

        // Ascending search
        let searchContentASC = searchString.match(pattern)

        if (searchContentASC !== null) {
          // searched content
          let matched = searchContentASC[0]

          let start = searchString.indexOf(matched)

          let end = start + matched.length

          let res = textParagraph.substring(start, end)

          // match not space after period
          let matchPeriod = res.match(/\.(?!\s)/g)

          if (matchPeriod !== null) {
            res = res.replace(/\.(?!\s)/g, '. ');
          }

          return res  + '...'
        }
      }
    },

    doLogout(){
      this.$store.commit('logoutUserToken')
      if(!this.userToken) {
        this.$router.push({ name: 'Login' })
      }
    },

    handleToProfile() {
      this.$router.push({ name: "Dashboard"})
    },

    async doSearch() {
      try{
        if(this.inputSearch){
          await this.doSearchNewsArticles()
          await this.doSearchRunEvents()
        } else {
          this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
        }
      } catch(err) {
        return err
      }
    },

    clearInputSearch(){
      this.$set(this, 'inputSearch', null)
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
    },
    
    pushToRunEvent(slug){
      this.$router.push({name: 'EventDetail', params: {pageSlug: slug}})
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
      this.closePopup()
    },

    pushToNewsArticles(slug){
      this.$router.push({name: 'NewsArticlesDetail', params: {pageSlug: slug}})
      this.$store.commit("home/CLEAR_SEARCH_SUGGEST")
      this.closePopup()
    }
  },
  watch: {
    'inputSearch': {
      handler(v) {
        if ((this.resultSearch || []).length > 0 || (this.resultRunEvents || []).length > 0) {
          this.$store.commit("home/CLEAR_SEARCH_RESULTS")
        }
      }
    }
  }
}

</script>