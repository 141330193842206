import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"

import Gen from "@/libs/Gen"

export default {
  namespaced: true,
  state: {
    alert: {},
    data_collection: {},
    text: {},
    isActivated: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_IS_ACTIVATED(state, data) {
      state.isActivated = data;
    },
    SET_REGISTER_CONTENT(state, data) {
      state.alert = data.alert
      state.data_collection = data.data_collection
      state.text = data.text
    },
    SET_LOGIN_CONTENT(state, data) {
      state.alert = data.alert
      state.text = data.text
    },
    SET_FORGOT_CONTENT(state, data) {
      state.alert = data.alert
      state.text = data.text
    },
  },
  actions: {
    setActivatedState({ commit }, value) {
      commit("SET_IS_ACTIVATED", value)
    },
    async getRegisterContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/register", {
          headers: Gen.apiHeader(),
        })

        commit("SET_REGISTER_CONTENT", resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
    async getLoginContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/login", {
          headers: Gen.apiHeader(),
        })

        commit("SET_LOGIN_CONTENT", resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
    async getForgotContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/forgot-password", {
          headers: Gen.apiHeader(),
        })

        commit("SET_FORGOT_CONTENT", resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    }
  },
}
