import { getField, updateField } from "vuex-map-fields"
import { http } from "@/config/http"
import Gen from "@/libs/Gen"

import router from "../../router/index"

export default {
  namespaced: true,
  state: {
    breadcrumb: null,
    hero: {},
    runevents: {},
    filters: {},
    programs: {},
    detailRunEvent: {},
    detailItineraries: [],
    detailBenefit: {
      benefitPackage: null,
      aboutPackage: null,
      hotelPackage: null,
    },
    detailPackage: {},
    paramsDetail: null,
    dataUser: {
      full_name: "",
      phone_number: "",
      email_address: "",
    },
    methodOfPayment: "PG",
    payloadTransaction: {
      registrant_type: "G",
      paymentOfType: "",
      full_name: "",
      first_name: "",
      last_name: "",
      citizenship_flag: "",
      citizenship: "",
      country_of_residence_flag: "",
      country_of_residence: "",
      email_address: "",
      phone_number: "",
      street_address_1: "",
      street_address_2: "",
      street_address_3: "",
      no_passport: "",
      expiration_passport: "",
      date_of_birthday: "",
      transaction_type: "B",
      gross_amount: null,
      slug: "",
      package: "",
      sub_package: "",
      date_of_birthday: "",
    },
    transactionData: null,
    detailTransaction: {},
    allHotels: null,
    refSignIn: null,
    payment_of_method_available: null
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    SET_RUN_EVENTS_CONTENT(state, data) {
      state.hero = data.hero
      state.runevents = data.runevents
      state.filters = data.filters
      state.programs = data.programs.data
      state.breadcrumb = [
        {
          to: "Home",
          text: "Home",
        },
        {
          to: null,
          text: "Run Events",
        },
      ]
    },

    SET_DETAIL_RUN_EVENT(state, data) {
      state.detailRunEvent = data
      state.breadcrumb = [
        {
          to: "Home",
          text: "Home",
        },
        {
          to: "RunEvents",
          text: "Run Event",
        },
        {
          to: null,
          text: data.event.name,
        },
      ]
    },

    SET_DETAIL_PACKAGE(state, data) {
      state.detailPackage = data
      state.payloadTransaction.gross_amount = data.booking_fee
      state.breadcrumb = [
        {
          to: "Home",
          text: "Home",
        },
        {
          to: "RunEvents",
          text: "Run Event",
        },
        {
          to: null,
          text: data.name,
        },
      ]
    },

    SET_DETAIL_ITINERARIES(state, data) {
      state.detailItineraries = data
    },

    SET_DETAIL_BENEFIT(state, data) {
      state.detailBenefit = data
    },

    SET_PARAMS_DETAIL(state, data = "1223") {
      state.paramsDetail = data
    },

    SET_PAYLOAD(state, data = {}) {
      state.payloadTransaction.package = data.package
      state.payloadTransaction.sub_package = data.sub_package
    },

    SET_TRANSACTION_DATA(state, data = {}) {
      state.transactionData = data
    },

    SET_DETAIL_TRANSACTION(state, data = {}) {
      state.detailTransaction = data
    },

    SET_ALL_HOTEL(state, data = {}) {
      state.allHotels = data
    },

    SET_MODAL_WARNING(state, value) {
      state.showModalWarning = value
    },

    SET_REF_SIGN_IN(state, data) {
      state.refSignIn = data
    },

    SET_METHOD_OF_PAYMENT_AVAILABLE(state, data) {
      state.payment_of_method_available = data.active_payments

      state.payloadTransaction.paymentOfType = data.default_payment
    },

  },
  
  actions: {
    async getRunEventsContent({ commit, rootState }, params = {}) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/runevents", {
          headers: {
            ...Gen.apiHeader(),
          },
          params: {
            ...params,
          },
        })

        commit("SET_RUN_EVENTS_CONTENT", resp.data)``
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        return error
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDetailRunEvents({ commit, rootState }, { path, callback }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/runevent/${path}`, {
          headers: {
            ...Gen.apiHeader(),
          },
          params: {
            page: "ED"
          }
        })

        commit("SET_DETAIL_RUN_EVENT", res.data)
        let resHotel = res.data.event.itineraries
        let allHotels = []
        let finalAllHotels = []

        for (const el of resHotel) {
          allHotels.push(Object.values(el.event_hotels))
        }
        for (const ea of allHotels) {
          for (let x = 0; x < ea.length; x++) {
            finalAllHotels.push(ea[x])
          }
        }

        finalAllHotels = finalAllHotels.filter(
          (thing, index, self) =>
            index ===
            self.findIndex((t) => t.event_hotel_name === thing.event_hotel_name)
        )

        commit("SET_ALL_HOTEL", finalAllHotels)

        let seo = {
          title: res.data.event.meta_title,
          description: res.data.event.meta_description,
          keywords: res.data.event.meta_keywords,
          img: `${rootState.apiUrl}/uploader/${res.data.event.preview_image}`, // og, twitter image
        }

        rootState.staticseo = seo
      } catch (err) {
        if ((err.response || {}).status === 400) {
          callback((err.response || {}).status)
        }
        if ((err.response || {}).status === 404) {
          return router.push({ name: "404" })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDetailPricings({ commit, rootState }, { path, callback }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/runevent/${path}`, {
          headers: {
            ...Gen.apiHeader(),
          },
          params: {
            page: "CE"
          }
        })

        if (res.data.event.available_tickets === 0) {
          router.push({ name: "RunEvents" })
        } else {
          commit("SET_DETAIL_RUN_EVENT", res.data)
          let resHotel = res.data.event.itineraries
          let allHotels = []
          let finalAllHotels = []

          for (const el of resHotel) {
            allHotels.push(Object.values(el.event_hotels))
          }
          for (const ea of allHotels) {
            for (let x = 0; x < ea.length; x++) {
              finalAllHotels.push(ea[x])
            }
          }

          finalAllHotels = finalAllHotels.filter(
            (thing, index, self) =>
              index ===
              self.findIndex(
                (t) => t.event_hotel_name === thing.event_hotel_name
              )
          )

          commit("SET_ALL_HOTEL", finalAllHotels)

          let seo = {
            title: res.data.event.meta_title,
            description: res.data.event.meta_description,
            keywords: res.data.event.meta_keywords,
          }

          rootState.staticseo = seo
        }
      } catch (err) {
        if ((err.response || {}).status === 400) {
          router.push({ name: "Home" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDetailPackage({ state, commit, rootState }, { path, callback }) {
      try {
        rootState.loading.screen = true
        if(Object.keys(rootState.user).length !== 0) {
          const res = await http.get(`/runevent/${path}/detail`, {
            headers: {
              ...Gen.apiHeader(),
            },
            params: state.paramsDetail,
          })

          commit("SET_DETAIL_PACKAGE", res.data.package_detail)
          commit("SET_METHOD_OF_PAYMENT_AVAILABLE", res.data.payment_of_method_available)
  
          let seo = {
            title: res.data.package_detail.meta_title,
            description: res.data.package_detail.meta_description,
            keywords: res.data.package_detail.meta_keywords,
          }
  
          rootState.staticseo = seo
  
          callback()
        } else {
          router.push({name: 'Login', query: {ref: router.app.$route.fullPath}}).catch(() => {})
        }
      } catch (err) {
        if ((err.response || {}).status === 400) {
          router.push({ name: "Home" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
      } finally {
        setTimeout(() => {
          rootState.loading.screen = false
        }, 1500)
      }
    },

    async getDetailTransaction({ state, commit, rootState }, param = {}) {
      try {
        if (JSON.stringify(param) === "{}") {
          return
        }

        const { path } = param

        rootState.loading.screen = true

        const token = Gen.getCookie("userAuth")

        const res = await http.get(`/transaction/${path}/summary`, {
          headers: {
            ...Gen.apiHeaderAuth(token),
          },
          params: state.transactionData,
        })

        commit("SET_DETAIL_TRANSACTION", res.data)

      } catch (err) {
        if ((err.response || {}).status === 400) {
          router.push({ name: "Home" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
        if ((err.response || {}).status === 301) {
          router.push({ name: err.response.data.redirect_to, params: {pageSlug: err.response.data.trx_id} })
        }
        if ((err.response || {}).status === 401) {
          router.push({
            name: "Login",
            query: { ref: router.currentRoute.fullPath },
          })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async postPayloadTransaction({ state, rootState, dispatch }) {
      try {
        rootState.loading.screen = true

        const resp = await Gen.apiRest(
          "/transactions",
          { data: Object.assign({}, state.payloadTransaction) },
          "POST"
        )

        if (resp.status === 201) {

          dispatch("watchAuth", null, { root: true })

          if(resp.data.is_not_redirect == "Y") {
            router.push({
              name: "DetailTransaction",
              query: {
                order_id: resp.data.order_id,
              },
            })
          } else {
            router.push({
              name: "DetailParentTransaction",
              params: {
                pageSlug: resp.data.transaction_id
              }
            })
          }
        }
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
