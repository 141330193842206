import { setToken, setBaseToken } from "../config/http"
import Gen from "@/libs/Gen"

function checkToken() {
  let token = Gen.getCookie("userAuth"),
    access = false

  setToken(token)
  token ? (access = true) : (access = false)

  return access
}

function test() {
  setBaseToken()
  return true
}

function baseAuthenticated(to, from, next) {
  test() ? next() : next({ name: "Login" })
}

function authenticated(to, from, next) {
  checkToken() ? next() : next({ name: "Login" })
}

function unauthenticated(to, from, next) {
  checkToken() ? next({ name: "Dashboard" }) : next()
}

export { checkToken, baseAuthenticated, authenticated, unauthenticated }
