<template>
  <div id="app">
    <LoadingScreen v-if="loading.screen" />
    <div v-if="!isMobile()">
      <WebHeaderBanner v-if="$route.meta.isHalfBanner"/>
      <WebHeaderNoBanner v-else-if="!$route.meta.isHalfBanner && !$route.meta.isNoBanner && $route.meta.isHeader" />
    </div>
    <div v-else>
      <MobileNavigation v-if="$route.meta.mobileNavigation"/>
    </div>
    <router-view />
    <div v-if="!isMobile()">
      <WebFooter v-if="$route.meta.isFooter" />
    </div>
    <div v-else-if="isMobile() && this.$route.name === 'Home'">
      <WebFooter />
    </div>
    <div v-else-if="isMobile() && $route.meta.isFooter">
      <WebFooter />
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex"

import GlobalVue from "./libs/Global.vue";

import WebHeaderBanner from "./components/WebHeaderBanner";
import WebHeaderNoBanner from "./components/WebHeaderNoBanner";
import WebFooter from "./components/WebFooter";
import MobileNavigation from "./components/MobileNavigation";
import LoadingScreen from "./components/LoadingScreen.vue";

export default {
  extends: GlobalVue,
  name: "App",
  metaInfo(){
    return {
      title: (this.staticseo || {}).title,
      titleTemplate: (this.staticseo || {}).title,
      meta: [
        { name: 'description', content: (this.staticseo || {}).description},
        { name: 'keywords', content: (this.staticseo || {}).keywords},
        
      ],
    }
  },

  components: {
    LoadingScreen,
    WebHeaderBanner,
    WebHeaderNoBanner,
    WebFooter,
    MobileNavigation,
  },

  computed: {
    ...mapFields(["baseUrl", "loading", "staticseo"]),
  },

  created() {
    this.uploader()
    this.watchMeta(this.$route.path)
  },

  methods: {
    ...mapActions([
      "watchMeta"
    ]),
  },

  watch: {
    '$route': {
      handler(o) {
        this.watchMeta(this.$route.path)
      }
    }
  }
};
</script>

<style lang="scss">
@import "../public/assets/scss/main.scss";

#app{
  overflow: hidden;
}
</style>
