<template>
  <div class="rdg_button">

    <template v-if="isPrevent">
      <div class="button primary" v-if="type == 'primary'" @click.prevent="$emit('clicked', $event.target.value)">
        <button :class="w100 ? 'w-100' : '' || small ? 'sm' : ''" :type="isSubmit ? 'submit' : ''">
          <span class="text-uppercase bold">{{ text }}</span>
        </button>
      </div>
    </template>

    <template v-else>
      <div class="button primary" v-if="type == 'primary'">
        <button :class="w100 ? 'w-100' : ''" :type="isSubmit ? 'submit' : ''">
          <span class="text-uppercase bold">{{ text }}</span>
        </button>
      </div>
    </template>

    <div class="button primary" v-if="type == 'primary-link'">
      <router-link class="text-uppercase bold" :target="target" :to="{ name: to }">{{ text }}</router-link>
    </div>

    <div class="button secondary_white" @click.prevent="$emit('clicked', $event.target.value)" v-if="type == 'secondary-white'">
      <button>
        <span class="text-uppercase normal">{{ text }}</span>
      </button>
    </div>

    <div class="button primary_icon" v-if="type == 'primary-icon'" @click="$emit('clicked', $event.target.value)">
      <button :class="[w100 ? 'w-100' : '', isFull ? 'full' : '']" :type="isSubmit ? 'submit' : ''">
        <span class="text-uppercase normal">{{ text }}</span>
        <div class="icon">
          <i class="ri-arrow-right-line"></i>
        </div>
      </button>
    </div>

    <div class="button secondary_icon" v-if="type == 'secondary-icon'" @click.prevent="$emit('clicked', $event.target.value)">
      <button>
        <span class="text-uppercase normal">{{ text }}</span>
        <div class="icon">
          <i class="ri-arrow-right-line"></i>
        </div>
      </button>
    </div>

    <div class="button secondary_icon_bgnone" v-if="type == 'secondary-icon-bgnone'" @click.prevent="$emit('clicked', $event.target.value)">
      <button>
        <span class="text-uppercase normal">{{ text }}</span>
        <div class="icon">
          <i class="ri-arrow-right-line"></i>
        </div>
      </button>
    </div>

    <div class="button secondary_border_icon" v-if="type == 'secondary-border-icon'" @click.prevent="$emit('clicked', $event.target.value)">
      <button>
        <span class="text-uppercase normal">{{ text }}</span>
        <div class="icon">
          <i class="ri-arrow-right-line"></i>
        </div>
      </button>
    </div>

    <div class="button nobg_icon" v-if="type == 'nobg-icon'" @click.prevent="$emit('clicked', $event.target.value)">
      <button>
        <span class="text-uppercase normal">{{ text }}</span>
        <div class="icon">
          <i class="ri-arrow-right-line"></i>
        </div>
      </button>
    </div>

    <div class="button nobg_black" v-if="type == 'nobg-black'" @click.prevent="$emit('clicked', $event.target.value)">
      <button>
        <span class="text-uppercase normal">{{ text }}</span>
      </button>
    </div>

    <div class="button nobg_linear" v-if="type == 'nobg-linear'" @click.prevent="$emit('clicked', $event.target.value)">
      <button>
        <span class="text-capitalize normal">{{ text }}</span>
      </button>
    </div>

    <div class="button disabled" v-if="type == 'disabled'">
      <button disabled>
        <span class="text-uppercase normal">{{ text }}</span>
      </button>
    </div>

  </div>
</template>

<script>

export default {
  name: "RdgButton",
  props: {
    type: String,
    text: String,
    w100: Boolean,
    isSubmit: Boolean,
    isPrevent: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: "_self"
    },
    to: String
  }
}

</script>

<style lang="scss" scoped>
@use "../../../public/assets/scss/util/variables";

.rdg_button{
  .button{
    button{
      padding: 1rem 1.5rem;
      border-radius: 99rem;
      border: none;
      outline: none;
      &.sm{
        padding: 0.5rem 1rem !important;
      }
    }

    a{
      padding: 1rem 1.5rem;
      border-radius: 99rem;
      border: none;
      outline: none;
    }

    &.secondary_white{
      button{
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      }
    }

    &.primary{
      button{
        background: variables.$secondary-linear;
        color: #fff;

        &:hover{
          background: variables.$secondary-hover-linear;
        }
      }

      a{
        background: variables.$secondary-linear;
        padding: 1rem ;
        color: #fff;

        &:hover{
          background: variables.$secondary-hover-linear;
        }
      }
    }

    &.primary_icon{
      button{
        display: flex;
        align-items: center;
        background: variables.$secondary-linear;
        color: #fff;
        span{
          margin-right: 1rem;
        }
        .icon{
          line-height: 25px;
          width: 25px;
          height: 25px;
          border-radius: 99rem;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          i{
            color: #000;
          }
        }

        &.full{
          justify-content: center;
        }
      }
    }

    &.secondary_icon{
      button{
        display: flex;
        align-items: center;
        background: #fff;
        span{
          margin-right: 1rem;
          background: variables.$secondary-linear;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .icon{
          width: 25px;
          height: 25px;
          border-radius: 99rem;
          line-height: 25px;
          background: variables.$secondary-linear;
          display: flex;
          justify-content: center;
          align-items: center;
          i{
            color: #fff;
          }
        }
      }
    }

    &.secondary_icon_bgnone{
      button{
        display: flex;
        align-items: center;
        background: transparent;
        border: 1px solid #fff;
        span{
          margin-right: 1rem;
          color: #fff;
        }
        .icon{
          width: 25px;
          height: 25px;
          border-radius: 99rem;
          line-height: 25px;
          background: variables.$secondary-linear;
          display: flex;
          justify-content: center;
          align-items: center;
          i{
            color: #fff;
          }
        }
        &:hover {
          span{
            text-decoration: underline;
          }
        }
      }
    }

    &.secondary_border_icon{
      border-radius: 99rem;
      width: 100%;
      button{
        $border: 1px;
        display: flex;
        width: 100%;
        background: #fff;
        align-items: center;
        background-clip: padding-box; /* !importanté */
        border: solid $border transparent; /* !importanté */
        border-radius: 99rem !important;
        position: relative;
        span{
          margin-right: 1rem;
          background: variables.$secondary-linear;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .icon{
          width: 25px;
          height: 25px;
          border-radius: 99rem;
          line-height: 25px;
          background: variables.$secondary-linear;
          display: flex;
          justify-content: center;
          align-items: center;
          i{
            color: #fff;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
          z-index: -1;
          margin: -$border; /* !importanté */
          border-radius: inherit; /* !importanté */
          background: variables.$secondary-linear;
        }
      }
    }

    &.nobg_icon{
      button{
        display: flex;
        align-items: center;
        background: transparent;
        padding: 1rem 0;
        span{
          margin-right: 1rem;
          color: #fff;
        }
        .icon{
          width: 25px;
          height: 25px;
          border-radius: 99rem;
          line-height: 25px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          i{
            background: variables.$secondary-linear;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    &.nobg_black{
      button{
        display: flex;
        align-items: center;
        background: transparent;
        padding: 1rem 0;
        span{
          margin-right: 1rem;
          color: #000;
        }
      }
    }

    &.nobg_linear{
      button{
        display: flex;
        align-items: center;
        background: #fff;
        padding: 1rem 1rem;
        span{
          text-decoration: underline;
          text-decoration-color: #000 !important;
          margin-right: 1rem;
          background: variables.$secondary-linear;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &.disabled{
      button{
        background: #ddd;
        color: #fff;
        cursor: not-allowed;
      }
    }
  }
}

</style>