import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"

import Gen from "@/libs/Gen"

export default {
  namespaced: true,
  state: {
    hero: {},
    about: {},
    whyrdg: {},
    services: {},
    features: {},
    partners: {},
    runevents: {},
    testimonials: {},
    gallery: {},
    ctaevents: {},
    newsarticles: {},
    feature: {},
    filters: {},
    inputSearch: null,
    input_program: null,
    resultSearch: null,
    resultRunEvents: null,
    totalNewsArticles: null,
    totalRunEvents: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_HOME_CONTENT(state, data) {
      state.hero = data.hero
      state.about = data.about
      state.whyrdg = data.whyrdg
      state.services = data.services
      state.features = data.features
      state.partners = data.partners
      state.runevents = data.runevents
      state.testimonials = data.testimonials
      state.gallery = data.gallery
      state.ctaevents = data.ctaevents
      state.newsarticles = data.newsarticles
      state.feature = data.feature
      state.filters = data.filters
    },

    SET_RESULT_SEARCH(state, data) {
      state.resultSearch = data
    },

    SET_RESULT_RUNEVENT(state, data) {
      state.resultRunEvents = data
    },

    SET_COUNT_NEWSARTICLES(state, data) {
      state.totalNewsArticles = data
    },

    SET_COUNT_RUNEVENTS(state, data) {
      state.totalRunEvents = data
    },

    CLEAR_SEARCH_SUGGEST(state) {
      state.inputSearch = null
      state.resultSearch = null
      state.resultRunEvents = null
    },

    CLEAR_SEARCH_RESULTS(state) {
      state.resultSearch = null
      state.resultRunEvents = null
    },
  },

  actions: {
    async getHomeContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/home", {
          headers: Gen.apiHeader(),
        })

        commit("SET_HOME_CONTENT", resp.data)

        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },

    async doSearchNewsArticles({ state, commit }) {
      try {
        const res = await Gen.apiRest(
          "/search-newsarticles",
          {
            data: Object.assign({}, { input_title: state.inputSearch }),
            headers: { ...Gen.apiHeader() },
          },
          "POST"
        )

        commit("SET_RESULT_SEARCH", res.data.newsarticles)
      } catch (err) {
        return err
      }
    },

    async doSearchRunEvents({ state, commit }) {
      try {
        const res = await Gen.apiRest(
          "/search-runevents",
          {
            data: Object.assign({}, { input_title: state.inputSearch }),
            headers: { ...Gen.apiHeader() },
          },
          "POST"
        )

        commit("SET_RESULT_RUNEVENT", res.data.runevents)
      } catch (err) {
        return err
      }
    },
  },
}
