import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"

import Gen from "@/libs/Gen"

export default {
  namespaced: true,
  state: {
    privacy: {},
    terms: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_PRIVACY_CONTENT(state, data) {
      state.privacy = data.privacy
    },
    SET_TERMS_CONTENT(state, data) {
      state.terms = data.terms
    }
  },
  actions: {
    async getPrivacyContent({ commit, rootState }, nostaticseo = false) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/privacy" + (nostaticseo ? "?static=0" : "?static=1"), {
          headers: Gen.apiHeader(),
        })

        commit("SET_PRIVACY_CONTENT", resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
    async getTermsContent({ commit, rootState }, nostaticseo = false) {
      try {
        rootState.loading.screen = true

        const resp = await http.get("/terms" + (nostaticseo ? "?static=0" : "?static=1"), {
          headers: Gen.apiHeader(),
        })

        commit("SET_TERMS_CONTENT", resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    }
  },
}
