import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "../../libs/Gen"

import router from "../../router/index"

export default {
  namespaced: true,
  state: {
    formSearch: {
      email_address: "",
      order_id: "",
    },
    bookData: [],
    laravelData: {},
    eventSlug: "",
    paidData: [],
    orderNumber: null,
    aboutTransaction: [],
    historyTransaction: [],
    transaction_id: null,
    filter: {},
    filters: {},
    partner: null,
    followUpQuestions: {
      confirmation_gender: null,
      confirmation_nationality: null,
      confirmation_first_name: null,
      confirmation_last_name: null,
      confirmation_passport_no: null,
      confirmation_passport_exp: null,
      confirmation_birth_date: null,
      confirmation_blood_type: null,
      confirmation_instagram: null,
      confirmation_email_address: null,
      confirmation_country_code: null,
      confirmation_mobile_phone: null,
      confirmation_address: null,
      confirmation_city: null,
      confirmation_district: null,
      confirmation_subdistrict: null,
      confirmation_zip_code: null,
      confirmation_state: null,
      confirmation_emergency_full_name: null,
      confirmation_emergency_mobile_phone: null,
      confirmation_emergency_relation: null,
      is_registrant: null,
      package_max_person: null,
      relation: [],
    },
    aboutFollowupQuestions: null,
    payment_of_method_available: null,
    form: {
      method_of_payment: null
    }
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_REGISTRANT_TO_FOLLOWUPQUESTIONS(state, data) {
      state.followUpQuestions.confirmation_gender = data.registrant_gender
      state.followUpQuestions.confirmation_birth_date =
        data.registrant_birth_date
      state.followUpQuestions.confirmation_blood_type =
        data.registrant_blood_type
      state.followUpQuestions.confirmation_instagram = data.registrant_instagram
      state.followUpQuestions.confirmation_address = data.registrant_address
      state.followUpQuestions.confirmation_city = data.registrant_city
      state.followUpQuestions.confirmation_district = data.registrant_district
      state.followUpQuestions.confirmation_subdistrict =
        data.registrant_subdistrict
      state.followUpQuestions.confirmation_zip_code = data.registrant_zip_code
      state.followUpQuestions.confirmation_state = data.registrant_state
      state.followUpQuestions.is_registrant = data.is_registrant
      state.followUpQuestions.registrant_id = data.registrant_id
    },

    SET_BOOK_DATA(state, data) {
      state.bookData = data
      state.formSearch.email_address = ""
      state.formSearch.order_id = ""
    },

    RESET_SEARCH_PURCHASE(state) {
      state.formSearch.email_address = ""
      state.formSearch.order_id = ""
    },

    SET_SEARCH_DATA(state, data) {
      state.bookData.order_id = data.order_id
      state.eventSlug = data.event_slug
      state.deep = true
      state.immediate = true
    },

    SET_PAID_DATA(state, data) {
      state.paidData = data.transactions
      state.filters = data.filters
    },

    SET_TRANSACTION_HISTORY_DATA(state, data) {
      state.historyTransaction = data.transactions
      state.partner = data.partner
      state.aboutTransaction = data.about
      state.payment_of_method_available = data.payment_of_method_available

      state.form.method_of_payment = data.payment_of_method_available.default_payment
    },

    SET_TRANSACTION_ID(state, data) {
      state.transaction_id = data
    },

    SET_FOLLOW_UP_QUESTIONS(state, data) {
      state.followUpQuestions = data
    },

    SET_ABOUT_FOLLOW_UP_QUESTIONS(state, data) {
      state.aboutFollowupQuestions = data
    },

  },
  actions: {
    async getBookData({ commit, rootState }, params = {}) {
      if (Gen.getCookie("userAuth") === "") {
        return
      }
      try {
        rootState.loading.screen = true

        const res = await http.get("/transactions/ongoing", {
          headers: {
            ...Gen.apiHeaderAuth(rootState.userToken),
          },

          params: {
            ...params,
          },
        })

        commit("SET_BOOK_DATA", res.data.transactions)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getPaidData({ commit, rootState }, params = {}) {
      try {
        if (
          Gen.getCookie("userAuth") === "" ||
          Gen.getCookie("userAuth") === null
        ) {
          return
        }
        rootState.loading.screen = true
        rootState.loading.data = true

        const res = await http.get("/transactions/paid", {
          headers: {
            ...Gen.apiHeaderAuth(rootState.userToken),
          },

          params: {
            ...params,
          },
        })

        commit("SET_PAID_DATA", res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
        setTimeout(() => {
          rootState.loading.data = false
        }, 1000)
      }
    },

    async getHistoryTransactionData({ commit, rootState }, orderNumber) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/transaction/${orderNumber}/histories`, {
          headers: {
            ...Gen.apiHeaderAuth(rootState.userToken),
          },
        })

        commit("SET_TRANSACTION_HISTORY_DATA", res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async searchOrderId({ commit, state, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get("/transactions/search", {
          headers: {
            ...Gen.apiHeaderAuth(rootState.userToken),
          },
          params: state.formSearch,
        })

        commit("SET_SEARCH_DATA", res.data.transaction)

        router.replace({
          name: "DetailTransaction",
          params: { pageSlug: state.eventSlug },
          query: { ...state.bookData },
        })
      } catch (err) {
        return (err.response || {}).status
      } finally {
        commit("RESET_SEARCH_PURCHASE")
        rootState.loading.screen = false
      }
    },

    async inactiveTransaction({ state, rootState, dispatch }) {
      try {
        rootState.loading.screen = true

        const res = await Gen.apiRest(
          "/transaction/inactive",
          { data: Object.assign({}, { transaction_id: state.transaction_id }) },
          "POST"
        )

        dispatch("getPaidData")
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async postMethodOfPayment({ state, rootState, dispatch }) {
      try {
        rootState.loading.screen = true

        const res = await Gen.apiRest(
          "/transaction/method-payment",
          { data: Object.assign({}, { 
            method_of_payment: state.form.method_of_payment,
            trx_id: state.aboutTransaction.trx_id
          }) },
          "POST"
        )
        
        if(res.status === 201) {
          dispatch("getHistoryTransactionData", state.aboutTransaction.trx_id)
          router.replace({
            name: "DetailTransaction",
            params: {
              pageSlug: state.aboutTransaction.trx_run_event.event_slug
            },
            query: {
              order_id: res.data.order_id
            }
          })
        }

      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getFollowUpQuestions({ rootState, commit }, token) {
      try {
        rootState.loading.screen = true

        let res = null

        if (Object.keys(rootState.user || {}).length == 0) {
          res = await http.get(`/confirmation/${token}`, {
            headers: {
              ...Gen.apiHeader(),
            },
          })
        } else {
          res = await http.get(`/confirmation/${token}`, {
            headers: {
              ...Gen.apiHeaderAuth(rootState.userToken),
            },
          })
        }

        commit("SET_FOLLOW_UP_QUESTIONS", res.data.data)
        commit("SET_ABOUT_FOLLOW_UP_QUESTIONS", res.data)

        if (Object.keys(rootState.user || {}).length !== 0) {
          if (res.data.is_followupquestion == "Y") {
            const registrant = rootState.user
            registrant.is_registrant = "Y"

            commit("SET_REGISTRANT_TO_FOLLOWUPQUESTIONS", registrant)
          }
        }
      } catch (err) {
        if (err.response.status === 400) {
          router.push({ name: "Home" })
        }
        if (err.response.status === 404) {
          router.push({ name: "404" })
        }
        if ((err.response || {}).status === 401) {
          router.push({
            name: "Login",
            query: { ref: router.currentRoute.fullPath },
          })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async submitFollowupQuestions({ rootState, state }, token) {
      try {
        rootState.loading.screen = true

        await Gen.apiRest(
          `/confirmation/${token}`,
          { data: Object.assign({}, state.followUpQuestions) },
          "POST"
        )

        return 200
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}
